import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import { resetUserPassword, updateFirstUserPassword } from "../../services/user.service";
import { Container } from "@mui/system";
import { useCallback, useState } from "react";
import { Stack, TextField, InputAdornment, IconButton, Grid, FormLabel, FormControl, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NewPasswordSchema } from "../../validations/NewPasswordSchema";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import TempLogo from "../../assets/svgs/temp-logo.svg";
import { ADD_NOTIFICATION, UPDATE_PASSWORD_RESET_STATUS } from "../../store/types";
import { useDispatch } from "react-redux";
import { signOut } from "../../utilis/signout";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ auth }) => ({ auth }));
  const [showPassword, setShowPassword] = useState(false);
  const { token = null } = useParams();
  const navigate = useNavigate();
  const { selectedAccount } = useSelector((state) => state.common);

  // eslint-disable-next-line no-unused-vars

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmNewPassword: "",
      token: token || null,
    },
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      if (auth._id) {
        const input = _.pick(values, ["password"]);
        await updateFirstUserPassword(auth._id, { ...input })
          .then(() => {
            dispatch({
              type: UPDATE_PASSWORD_RESET_STATUS,
              payload: false
            });
           
            if(selectedAccount?._id){
              navigate(`/dashboard/hospital/?accountId=${selectedAccount?._id}`);
            }
            else {
              signOut(navigate)
            }
            resetForm();
            setSubmitting(false);
            // setTimeout(, 2000);
          })
          .catch((err) => {
            setSubmitting(false);
          });
      } else if (token) {
        await resetUserPassword(values)
          .then(() => {
            signOut(navigate)
            resetForm();
            setSubmitting(false);
            // setTimeout(, 2000);
          })
          .catch((err) => {
            setSubmitting(false);
          });
      } else {
        dispatch({
          type: ADD_NOTIFICATION,
          payload: {
            type: "error",
            label: "Error while update password",
            id: "updateUserError",
          },
        });
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  return (
    <>
      <Container maxWidth="sm" alignContent="center">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid sx={{ padding: 5 }}>
            <TempLogo />
          </Grid>
          <Grid spacing={4} direction="row">
            <Typography variant="h5">New password</Typography>
          </Grid>
          <Grid spacing={4} sx={{ mt: 2 }} width="80%">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2} maxWidth="sm">
                  <Stack spacing={3}>
                    <FormControl>
                      <FormLabel>New Password</FormLabel>
                      <TextField
                        {...getFieldProps("password")}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        autoFocus
                        focused
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end" size="small">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Confirm New Password</FormLabel>
                      <TextField
                        {...getFieldProps("confirmNewPassword")}
                        fullWidth
                        autoComplete="off"
                        type="password"
                        error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                        helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      loadingIndicator="Loading..."
                    >
                      Update Password
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetPassword;
