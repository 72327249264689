import { Box, Button, Checkbox, Grid, Input, InputAdornment, Popover, Skeleton, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPercentageThresholds } from "../../../store/reducers/comparisonReducers/comparison.slice";
import { ADD_NOTIFICATION } from "../../../store/types";
import { COLORS_BOX_CODE } from "../../../types/common.type";
import styles from "./ColorBoxes.module.scss";
import { PAGE_TYPE } from "../../../types/pages.type";
import { dateFormat } from "../../../utilis/date-formats";

const ColorItem = ({ color, label, borderColor }) => {
	return (
		<Grid item>
			<Stack direction={"row"} alignItems={"center"}>
				<Box
					sx={{
						background: color,
						width: "20px",
						height: "28px",
						borderRadius: "5px",
						border: `1px solid ${borderColor}`,
					}}
				></Box>
				<Typography
					sx={{
						textAlign: "center",
						ml: "20px",
						color: "#000",
						fontFamily: "manrope",
						fontSize: "14px",
						fontWeight: 500,
					}}
				>
					{label}
				</Typography>
			</Stack>
		</Grid>
	);
};

const RedefinePercentagesPopup = ({ open, anchorRef, handleClose, percentageThresholds }) => {
	const dispatch = useDispatch();
	const [localPercentages, setLocalPercentages] = useState(percentageThresholds);

	useEffect(() => {
		setLocalPercentages(percentageThresholds);
	}, [percentageThresholds, open]);

	const getMinValue = (label, state) => {
		switch (label) {
			case "veryGood":
				return state["good"].value + 1;
			case "good":
				return state["littleGood"].value + 1;
			case "littleGood":
			case "littleBad":
				return 0;
			case "bad":
				return state["littleBad"].value + 1;
			case "veryBad":
				return state["bad"].value + 1;
			default:
				return 0;
		}
	};

	const setPercentage = (event, label) => {
		const payload = Number(event.target.value);
		!isNaN(payload) &&
			setLocalPercentages((oldState) => {
				return {
					...oldState,
					[label]: { ...oldState[label], value: payload },
				};
			});
	};

	const onBlurPercentage = (event, label) => {
		const payload = Number(event.target.value);
		!isNaN(payload) &&
			setLocalPercentages((oldState) => {
				const minValue = getMinValue(label, localPercentages);
				const newValue = Math.max(minValue, payload);

				return {
					...oldState,
					[label]: { ...oldState[label], value: newValue },
				};
			});
	};

	const onSaveButton = () => {
		if (
			Object.values(localPercentages).every((x) => {
				const numberValue = Number(x.value);

				return !isNaN(numberValue) && numberValue > 0;
			})
		) {
			dispatch(setPercentageThresholds(localPercentages));
			handleClose();
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: "Wrong values. Must be a number and above 0",
					type: "error",
					id: "percentageThresholds",
				},
			});
		}
	};

	return (
		<Popover
			id="redefine-percentages-popover"
			open={open}
			anchorEl={anchorRef.current}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
		>
			<Box className={styles.redefinePercentagesPopupWrapper}>
				<Grid container className={styles.inputGroups}>
					<Grid container direction="column" className={styles.inputGroup}>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.RED} borderColor={COLORS_BOX_CODE.RED} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.RED }}>
								(RED):
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="veryBad"
								value={localPercentages.veryBad.value || ""}
								onChange={(e) => setPercentage(e, "veryBad")}
								onBlur={(e) => onBlurPercentage(e, "veryBad")}
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> worse</Typography>
						</Box>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.ORANGE} borderColor={COLORS_BOX_CODE.ORANGE} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.ORANGE }}>
								(ORANGE):{" "}
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="bad"
								value={localPercentages.bad.value || ""}
								onChange={(e) => setPercentage(e, "bad")}
								onBlur={(e) => onBlurPercentage(e, "bad")}
								variant="outlined"
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> to </Typography>
							<Input
								id="badDisabled"
								value={(localPercentages.veryBad.value || 1) - 1 || ""}
								variant="outlined"
								disabled
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> worse</Typography>
						</Box>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.YELLOW} borderColor={COLORS_BOX_CODE.YELLOW} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.YELLOW }}>
								(YELLOW):
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="littleBad"
								value={localPercentages.littleBad.value || ""}
								onChange={(e) => setPercentage(e, "littleBad")}
								onBlur={(e) => onBlurPercentage(e, "littleBad")}
								variant="outlined"
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> to </Typography>
							<Input
								id="littleBadDisabled"
								value={(localPercentages.bad.value || 1) - 1 || ""}
								variant="outlined"
								disabled
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> worse</Typography>
						</Box>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.LIGHT_GREEN} borderColor={COLORS_BOX_CODE.LIGHT_GREEN} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.LIGHT_GREEN }}>
								(LIGHT GREEN):{" "}
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="littleGood"
								value={localPercentages.littleGood.value || ""}
								onChange={(e) => setPercentage(e, "littleGood")}
								onBlur={(e) => onBlurPercentage(e, "littleGood")}
								variant="outlined"
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> to </Typography>
							<Input
								id="littleGoodDisabled"
								defaultValue={(localPercentages.good.value || 1) - 1 || ""}
								variant="outlined"
								disabled
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> better</Typography>
						</Box>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.GREEN} borderColor={COLORS_BOX_CODE.GREEN} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.GREEN }}>
								(GREEN):{" "}
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="good"
								value={localPercentages.good.value || ""}
								onChange={(e) => setPercentage(e, "good")}
								onBlur={(e) => onBlurPercentage(e, "good")}
								variant="outlined"
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> to </Typography>
							<Input
								id="goodDisabled"
								value={(localPercentages.veryGood.value || 1) - 1 || ""}
								variant="outlined"
								disabled
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> better</Typography>
						</Box>
						<Box className={styles.inputBox}>
							<ColorItem color={COLORS_BOX_CODE.BLUE} borderColor={COLORS_BOX_CODE.BLUE} />
							<Typography className={styles.oneLineTypo} sx={{ color: COLORS_BOX_CODE.BLUE }}>
								(BLUE):{" "}
							</Typography>
							<Typography className={styles.secondaryTypo}>&nbsp;&gt;= </Typography>
							<Input
								id="veryGood"
								value={localPercentages.veryGood.value || ""}
								onChange={(e) => setPercentage(e, "veryGood")}
								onBlur={(e) => onBlurPercentage(e, "veryGood")}
								size="small"
								endAdornment={
									<InputAdornment position="end" sx={{ marginRight: "5px" }}>
										<Typography sx={{ fontSize: "10px" }}> % </Typography>
									</InputAdornment>
								}
								sx={{
									paddingLeft: "7px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									width: "47px",
									marginLeft: "10px",
									marginRight: "10px",
								}}
							/>
							<Typography className={styles.secondaryTypo}> better</Typography>
						</Box>
					</Grid>
				</Grid>
				<Box className={styles.buttonWrapper}>
					<Button onClick={onSaveButton} variant="contained" size="small">
						Save
					</Button>
				</Box>
			</Box>
		</Popover>
	);
};

export default function ColorInfoBox({ style, loading, pageType = null }) {
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const [popupOpened, setPopupOpened] = useState(false);
	const anchorRef = useRef(null);
	const dispatch = useDispatch();
	const { comparisonRanges: ranges } = useSelector((state) => state.comparison);

	const closePopup = useCallback(() => {
		setPopupOpened(false);
	}, []);

	const setShowColor = (event, label) => {
		const checked = Number(event.target.checked);
		!isNaN(checked) &&
			dispatch(
				setPercentageThresholds({
					...percentageThresholds,
					[label]: { ...percentageThresholds[label], showColor: checked },
				})
			);
	};

	const RedColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.veryBad.showColor}
				onChange={(e) => setShowColor(e, "veryBad")}
				title={`${percentageThresholds?.veryBad.showColor ? "Hide" : "Show"} red color`}
			/>
			<ColorItem
				color={"#F30000"}
				label={`(RED): >= ${percentageThresholds?.veryBad.value}% worse`}
				borderColor={COLORS_BOX_CODE.RED}
			/>
		</>
		)
	}
	const OrangeColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.bad.showColor}
				onChange={(e) => setShowColor(e, "bad")}
				title={`${percentageThresholds?.bad.showColor ? "Hide" : "Show"} orange color`}
			/>
			<ColorItem
				color={"#FF7A00"}
				label={`(ORANGE): >= ${percentageThresholds?.bad.value}% to ${percentageThresholds?.veryBad.value - 1
					}% worse`}
				borderColor={COLORS_BOX_CODE.ORANGE}
			/>
		</>
		)
	}
	const YellowColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.littleBad.showColor}
				onChange={(e) => setShowColor(e, "littleBad")}
				title={`${percentageThresholds?.littleBad.showColor ? "Hide" : "Show"} yellow color`}
			/>
			<ColorItem
				color={"#FFD600"}
				label={`(YELLOW):  >= ${percentageThresholds?.littleBad.value}% to ${percentageThresholds?.bad.value - 1
					}% worse`}
				borderColor={COLORS_BOX_CODE.YELLOW}
			/>
		</>
		)
	}
	const LightGreenColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.littleGood.showColor}
				onChange={(e) => setShowColor(e, "littleGood")}
				title={`${percentageThresholds?.littleGood.showColor ? "Hide" : "Show"} light green color`}
			/>
			<ColorItem
				color={"#00FFC2"}
				label={`(LIGHT GREEN): >= ${percentageThresholds?.littleGood.value}% to ${percentageThresholds?.good.value - 1
					}% better`}
				borderColor={COLORS_BOX_CODE.LIGHT_GREEN}
			/>
		</>
		)
	}
	const GreenColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.good.showColor}
				onChange={(e) => setShowColor(e, "good")}
				title={`${percentageThresholds?.good.showColor ? "Hide" : "Show"} green color`}
			/>
			<ColorItem
				color={"#0F0"}
				label={`(GREEN): >= ${percentageThresholds?.good.value}% to ${percentageThresholds?.veryGood.value - 1
					}% better`}
				borderColor={COLORS_BOX_CODE.GREEN}
			/>
		</>
		)
	}
	const BlueColorBox = () => {
		return (<>
			<Checkbox
				checked={percentageThresholds?.veryGood.showColor}
				onChange={(e) => setShowColor(e, "veryGood")}
				title={`${percentageThresholds?.veryGood.showColor ? "Hide" : "Show"} blue color`}
			/>
			<ColorItem
				color={"#0038FF"}
				label={`(BLUE): >= ${percentageThresholds?.veryGood.value}% better`}
				borderColor={COLORS_BOX_CODE.BLUE}
			/>
		</>
		)
	}

	if (pageType === PAGE_TYPE.OVERALL) {
		return (!loading ? (
			<Grid container direction={"column"} alignItems={"flex-start"}>
				<Grid item>
					<Typography
						className={styles.redefinePercentages}
						onClick={() => setPopupOpened((oldState) => !oldState)}
						ref={anchorRef}
					>
						Redefine percentages
					</Typography>
					<RedefinePercentagesPopup
						open={popupOpened}
						anchorRef={anchorRef}
						handleClose={closePopup}
						percentageThresholds={percentageThresholds}
					/>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<RedColorBox />
					</Stack>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<OrangeColorBox />
					</Stack>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<YellowColorBox />
					</Stack>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<LightGreenColorBox />
					</Stack>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<GreenColorBox />
					</Stack>
				</Grid>
				<Grid item>
					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<BlueColorBox />
					</Stack>
				</Grid>
				<Grid item	>
					<Typography variant="body2">
						Comparison date: {ranges && ranges[0] && ranges[0].startDate && ranges[0].endDate && dateFormat(ranges[0].startDate) + " - " + dateFormat(ranges[0].endDate)}
					</Typography>
				</Grid>
			</Grid>
		) : <Grid
			container
			direction="column"
			spacing={1}
			sx={{ display: "flex", pl: 2, height: "100vh", width: "100%" }}
			alignItems="flex-start"
		>
			{/* Skeleton for "Redefine percentages" Typography */}
			<Grid item sx={{ width: '100%' }}>
				<Skeleton variant="text" width="100%" height={30} />
			</Grid>

			{/* Skeleton for RedColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for OrangeColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for YellowColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for LightGreenColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for GreenColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for BlueColorBox */}
			<Grid item sx={{ width: '100%' }}>
				<Stack direction="row" spacing={1} alignItems="center">
					<Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: '8px' }} />
				</Stack>
			</Grid>

			{/* Skeleton for Comparison Date */}
			<Grid item sx={{ width: '100%' }}>
				<Skeleton variant="text" width="100%" height={30} sx={{ borderRadius: '8px' }} />
			</Grid>
		</Grid>);
	}

	return (
		!loading && (
			<Box style={style} position={style?.position || "absolute"} sx={{ top: pageType === PAGE_TYPE.OVERALL ? "0%" : "25%", right: pageType === PAGE_TYPE.OVERALL ? "0%" : "5%" }}>
				<Typography
					className={styles.redefinePercentages}
					onClick={() => setPopupOpened((oldState) => !oldState)}
					ref={anchorRef}
				>
					Redefine percentages
				</Typography>
				<RedefinePercentagesPopup
					open={popupOpened}
					anchorRef={anchorRef}
					handleClose={closePopup}
					percentageThresholds={percentageThresholds}
				/>
				{pageType === PAGE_TYPE.OVERALL ?
					<Grid direction={"row"} >
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<RedColorBox />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<OrangeColorBox />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<YellowColorBox />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<LightGreenColorBox />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<GreenColorBox />
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
								<BlueColorBox />
							</Stack>
						</Grid>
					</Grid> : (
						<Grid container direction={"column"} spacing={1}>
							<Grid container>
								<RedColorBox />
							</Grid>
							<Grid container>
								<OrangeColorBox />
							</Grid>
							<Grid container>
								<YellowColorBox />
							</Grid>
							<Grid container>
								<LightGreenColorBox />
							</Grid>
							<Grid container>
								<GreenColorBox />
							</Grid>
							<Grid container>
								<BlueColorBox />
							</Grid>
						</Grid>
					)}
				<Typography variant="body2">
					Comparison date: {ranges && ranges[0] && ranges[0].startDate && ranges[0].endDate && dateFormat(ranges[0].startDate) + " - " + dateFormat(ranges[0].endDate)}
				</Typography>
			</Box>
		)
	);
}
