import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/admission.slice";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/admissionComparison.slice";
import {
	getAllAdmissionCount,
	getAllADTData,
	getCardADTPatientChartData,
	getCardPatientAndChartData,
	getCardPatientData,
} from "./api/admission.api";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const ongoingPatientRequestsChart = new Map();

const cardPatientAndChartData = async (data, type = null) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	// Check for duplicate requests
	if (ongoingPatientRequestsChart.has(requestKey)) {
		const { controller } = ongoingPatientRequestsChart.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequestsChart.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;
	try {
		const requestPromise = getCardPatientAndChartData(data, { signal });
		ongoingPatientRequestsChart.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;

		return res;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientRequestsChart.delete(requestKey);
	}
};

const ongoingPatientRequestsADTChart = new Map();

const cardADTPatientChartData = async (data, type = null) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	// Check for duplicate requests
	if (ongoingPatientRequestsADTChart.has(requestKey)) {
		const { controller } = ongoingPatientRequestsADTChart.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequestsADTChart.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;
	try {
		const requestPromise = getCardADTPatientChartData(data, { signal });
		ongoingPatientRequestsADTChart.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;

		return res;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientRequestsADTChart.delete(requestKey);
	}
};

const ongoingPatientRequests = new Map();

const getAllAdmissionsPatient = async (data = null, forComparison = null, type) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	// Check for duplicate requests
	if (ongoingPatientRequests.has(requestKey)) {
		const { controller } = ongoingPatientRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {
		const requestPromise = getAllAdmissionCount(data, { signal });
		ongoingPatientRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));

		return res.data;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientRequests.delete(requestKey);
	}
};

// const getAllAdmissionsPatientOld = async (data = null, forComparison) => {
// 	try {
// 		const res = await getAllAdmissionCount(data);
// 		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
// 		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));
// 		return res.data;
// 	} catch (e) {
// 		console.log(e);
// 	}
// };

const ongoingPatientADTRequests = new Map();

const allADTData = async (data, type = "default") => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	// Check for duplicate requests
	if (ongoingPatientADTRequests.has(requestKey)) {
		const { controller } = ongoingPatientADTRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientADTRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;


	try {
		const requestPromise = getAllADTData(data, { signal });
		ongoingPatientADTRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;
		return res;
		// return await getAllADTData(data);
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientADTRequests.delete(requestKey);
	}
};

export { allADTData, cardADTPatientChartData, cardPatientAndChartData, cardPatientData, getAllAdmissionsPatient };

