import { Button } from "@mui/material";
import styles from "./AddPatient.module.scss";

const TrendAnalysisOffDialog = ({ selectedItem, close, hideHeader }) => {
    const { errors } = selectedItem;
    return (
        <div className={styles.validationADT}>
            <div className={styles.validationADTOverlay}></div>
            <div className={styles.validationADTContent}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmm fs16`}>Trend Analysis Turned Off</p>
                </div>
                <div className={`p-t-8 p-r-20 p-b-20 p-l-20 df ffc ${styles.stepThree}`}>
                    <ui className={`m-t-10`}>
                        {errors &&
                            errors.length > 0 &&
                            errors.map((item, index) => (
                                <li key={index}>
                                    <span>{item}</span>
                                </li>
                            ))}
                    </ui>
                </div>
                <Button variant="contained" sx={{ ml: "35%", mb: 2, width: "100px", height: "40px" }} onClick={() => close()}>
                    Ok
                </Button>
            </div>
        </div>
    );
};

export default TrendAnalysisOffDialog;
