import * as React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import NotesList from './notes/NotesList';


export default function NoteContainer({ children, page, openFrom = "page" }) {
    const { isNotePopup, isNoteExpanded } = useSelector((state) => state.common);

    const [rightContentVisible, setRightContentVisible] = React.useState(false);
    const [rightContentWidth, setRightContentWidth] = React.useState(300); // Default width

    React.useEffect(() => { // Set the right content width based on the isNoteExpanded state
        setRightContentWidth(isNoteExpanded ? 500 : 300);
    }, [isNoteExpanded]);

    React.useEffect(() => {
        setRightContentVisible(isNotePopup);
        setRightContentWidth(300);
    }, [isNotePopup]);

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                width="100%"
                // style={{                    
                //     // overflow: "auto",
                //     // height: "100vh",
                // }}
            >
                <Box
                    flex="1"
                    style={{
                        transition: "margin-right 0.3s",
                        overflow: openFrom === "page" ? "auto" : "hidden",
                    }}
                >
                    {children}
                </Box>

                {rightContentVisible && (
                    <Box
                        style={{
                            transition: "width 0.3s", // Smooth transition for width
                            width: rightContentWidth,
                            backgroundColor: "#fff",
                            overflow: "hidden",
                            flexShrink: 0, // Prevent shrinking
                            marginTop: openFrom === "page" ? "80px" : "16px",                          
                            ...(openFrom === "dialog" && { borderRadius: "4px" }),
                        }}
                        sx={{
                            ...(openFrom === "dialog" && { boxShadow: 3 }),
                        }}
                    >
                        <Box padding={2}>
                            <NotesList
                                page={page}
                                openFrom={openFrom}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}
