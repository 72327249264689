import api from './api';

const API_PREFIX = "api/note";

/**
 * Creates a new note.
 * @param {Object} body - The note data to be sent in the request body.
 * @returns {Promise<Object>} The API response.
 */
const createNoteData = async (body) => {
    try {
        const response = await api.post(`${API_PREFIX}`, body);
        return response;
    } catch (error) {
        console.error("Error creating note:", error);
        throw error;
    }
};

/**
 * Fetches all notes based on the provided parameters.
 * @param {Object} params - Query parameters for fetching notes.
 * @returns {Promise<Object>} The API response.
 */
const getNotesData = async (params) => {
    try {
        const response = await api.get(`${API_PREFIX}/all`, { params });
        return response;
    } catch (error) {
        console.error("Error fetching notes:", error);
        throw error;
    }
};

/**
 * Deletes a note by its ID.
 * @param {string} id - The ID of the note to delete.
 * @returns {Promise<Object>} The API response.
 */
const deleteNoteData = async (id) => {
    try {
        const response = await api.delete(`${API_PREFIX}/${id}`);
        return response;
    } catch (error) {
        console.error("Error deleting note:", error);
        throw error;
    }
};

export {
    createNoteData,
    getNotesData,
    deleteNoteData
};