import {
    Box,
    Collapse,
    Fab,
    Grid,
    Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
// import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { PAGE_TYPE } from "../../../types/pages.type";
import { useDispatch } from "react-redux";
import { setIsScrollHightLight } from "../../../store/reducers/common.slice";
import FilterCardList from "./FilterCardList";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const AddCardDrawer = ({
    listData,
    handleAddCustomCard,
    selectedCards,
    sx,
    page,
    height,
    scrollHeight,
    scrollWidth,
    onStateUpdate,
    position,
    scrollTop,
}) => {
    const [checked, setChecked] = useState(false);
    const [isOpenCloseAll, setIsOpenCloseAll] = useState(false);
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const fabButtonRef = useRef();

    useEffect(() => {
        onStateUpdate && onStateUpdate(checked);
    }, [checked, onStateUpdate]);

    const scrollToSection = (targetId) => {
        const id = `${targetId}_scroll`;
        const element = document.getElementById(id);
        setTimeout(() => {
            dispatch(setIsScrollHightLight(id));
            setTimeout(() => {
                dispatch(setIsScrollHightLight(null));
            }, 3800);
        }, 500);

        if (element) {
            element.scrollIntoView({
                behavior: "auto",
                block: "center",
                // inline: "center",
            });
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        anchorEl ? handleClose() : setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setChecked(false)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'custom-filter-menu-popover' : undefined;

    useEffect(() => {
        if (checked && scrollTop > 100) {
            setAnchorEl(null)
        }
    }, [checked, scrollTop]);

    return (
        <>
            {scrollTop > 200 && page !== PAGE_TYPE.OVERALL && <>
                <Fab
                    sx={{
                        position: "fixed",
                        top: { sm: "15%", xs: "30%", md: "21%", lg: "20%", xl: "20%" },
                        left: "calc(var(--sidebar-width) + 25px)",
                        webkitAnimation: "fadeIn 5s",
                        animation: "fadeIn 5s",
                        color: open ? "#fff" : "#103996",
                        background: open ? "#103996" : "#fff",
                        boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.4)",
                        ":hover": {
                            background: open ? "#103996" : "#fff",
                            boxShadow: "0px 0px 14px rgba(72, 121, 245, 0.3)"
                        }
                    }}
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={handleClick}
                    ref={fabButtonRef}
                >
                    {open ? (
                        <KeyboardArrowUpOutlinedIcon sx={{ color: open ? "#fff" : "#103996" }} />
                    ) : (
                        <KeyboardArrowDownOutlinedIcon sx={{ color: open ? "#fff" : "#103996" }} />
                    )}
                </Fab>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    disableScrollLock={false}
                    onClose={handleClose}
                    placement="bottom-start"
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: false,
                            options: {
                                altBoundary: false,
                                rootBoundary: 'document',
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                            enabled: false,
                            options: {
                                altAxis: false,
                                altBoundary: false,
                                tether: false,
                                rootBoundary: 'document',
                                padding: 8,
                            },
                        },
                        {
                            name: 'arrow',
                            enabled: true,
                        },
                        {
                            name: "offset",
                            options: {
                                offset: [0, 18],
                            },
                        },
                    ]}
                    transition
                    keepMounted
                >
                    {({ TransitionProps }) => (
                        <Collapse
                            {...TransitionProps}
                            timeout={1000}
                        // easing={{ enter: 'cubic-bezier(0,-1.55,.61,1.58)', exit: 'linear' }}

                        >
                            <Box sx={{
                                p: 0,
                                bgcolor: 'background.paper',
                                border: '1px solid #E6E8ED',
                                borderRadius: 1,
                                zIndex: 100000
                            }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        mt: "3px",
                                        "&::before": {
                                            backgroundColor: "#fff",
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            width: 20,
                                            height: 20,
                                            top: "-1px",
                                            left: 15,
                                            transform: 'translate(-50%, -50%) rotate(45deg)',
                                            border: '1px solid #E6E8ED',
                                        }
                                    }}
                                />
                                <FilterCardList
                                    setSearchText={setSearchText}
                                    searchText={searchText}
                                    isOpenCloseAll={isOpenCloseAll}
                                    handleAddCustomCard={handleAddCustomCard}
                                    setIsOpenCloseAll={setIsOpenCloseAll}
                                    scrollHeight={scrollHeight}
                                    scrollWidth={scrollWidth}
                                    listData={listData}
                                    selectedCards={selectedCards}
                                    scrollToSection={scrollToSection}
                                    type="scroll"
                                />
                            </Box>
                        </Collapse>
                    )}
                </Popper>
            </>}
            <Box
                {...(position && { position })}
                sx={{
                    ...(page !== PAGE_TYPE.OVERALL && {
                        position: "absolute",
                        top: {
                            xl: "18%",
                            lg: "20%",
                            xs: "19%"
                        },
                        marginLeft: "20px",
                        zIndex: 99,
                        width: {
                            xs: "26%",
                            xl: "30%"
                        },
                    }),
                    ...sx,
                }}
                className={page === PAGE_TYPE.OVERALL ? "overall-additional-box" : ""}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={page !== PAGE_TYPE.OVERALL ? 1 : 0}
                >
                    <Grid xs={12}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                        >
                            <Grid item xs={12}>
                                <div
                                    class={`customFilterBoxContent ${checked ? "selected " : null}`}
                                    onClick={() => setChecked(!checked)}
                                >
                                    <span class="customFilterBoxHeader">
                                        Additional Data Tabs
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Collapse orientation="vertical" in={checked} timeout={1000}>
                            <FilterCardList
                                setSearchText={setSearchText}
                                searchText={searchText}
                                isOpenCloseAll={isOpenCloseAll}
                                handleAddCustomCard={handleAddCustomCard}
                                setIsOpenCloseAll={setIsOpenCloseAll}
                                scrollHeight={scrollHeight}
                                scrollWidth={scrollWidth}
                                listData={listData}
                                selectedCards={selectedCards}
                                scrollToSection={scrollToSection}
                                page={page}
                            />
                        </Collapse>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddCardDrawer;