import { combineReducers } from "redux";
import auth from "./authR";
import notification from "./notificationR";
import account from "./accountR";
import facility from "./facilityR";
import permissions from "./permissions";
import hospital from "./hospital.slice";
import admission from "./admission.slice";
import permission from "./permission.slice";
import communityTransfer from "./community-transfer.slice";
import deceased from "./deceased.slice";
import overall from "./overall.slice";
// import quickGlace from "./quick-glace.slice";
import dataCardsExpand from "./dataCardsExpand.slice";
import activeFacilities from "./activeFacilities.slice";
import hospitalComparison from "./comparisonReducers/hospitalComparison.slice";
import admissionComparison from "./comparisonReducers/admissionComparison.slice";
import communityTransferComparison from "./comparisonReducers/communityTransferComparison.slice";
import deceasedComparison from "./comparisonReducers/deceasedComparison.slice";
import overallComparison from "./comparisonReducers/overallComparison.slice";
import comparison from "./comparisonReducers/comparison.slice";
import customCard from "./customCard.slice";
import common from "./common.slice";
import  noteReducer  from "../../services/note.service";

export default combineReducers({
  customCard,
  auth,
  notification,
  account,
  facility,
  hospital,
  hospitalComparison,
  admissionComparison,
  communityTransferComparison,
  deceasedComparison,
  overallComparison,
  permissions,
  permission,
  admission,
  communityTransfer,
  deceased,
  overall,
  // quickGlace,
  dataCardsExpand,
  activeFacilities,
  comparison,
  common,
  note: noteReducer,
});
