/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Page from "../components/Page";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import Card from "../components/dashboard/card/Card";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import {
	openDetailsDialog as openDetailsDialogComparison,
	setCardFilterOverall as setCardFilterComparison,
	setFilterListDataOverall as setFilterListDataComparison,
	setPatientList as setPatientListComparison,
	setTransferType as setTransferTypeComparison,
} from "../store/reducers/comparisonReducers/overallComparison.slice";
import {
	DEFAULT_OVERALL_DATA,
	openDetailsDialog,
	setCardFilterOverall,
	setDefaultData,
	setIsMainCensusPercentage,
	setLockedByFacility,
	setLockedTotalBy,
	setLockTotal,
	setMainTotal,
	setResetFilter,
	setTransferType,
} from "../store/reducers/overall.slice";

import { Box, Collapse, Grid, Slide } from "@mui/material";
import update from "immutability-helper";
import _ from "lodash";
import useComparisonFunctions from "../components/comparison-popup/useComparisonFunctions";
import HospitalRowContainer from "../components/dashboard/hospital/HospitalRowContainer";
import NinetyDaysAnalysis from "../components/dashboard/hospital/ninetyDaysAnalysis/NinetyDaysAnalysis";
import OverallCardDetailsDialog from "../components/dashboard/overall/OverallCardDetailsDialog";
import OverallHeader from "../components/dashboard/overall/OverallHeader";
import TotalOverallCard from "../components/dashboard/overall/totalOverallCard/TotalOverallCard";
import TotalOverallIncomingCard from "../components/dashboard/overall/totalOverallCard/TotalOverallIncomingCard";
import TotalOverallOutGoingCard from "../components/dashboard/overall/totalOverallCard/TotalOverallOutGoingCard";
import SelectableCard from "../components/dashboard/selectableCard/SelectableCard";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import PickProjectionDaysPopup from "../components/shared/PickProjectionDaysPopup/PickProjectionDaysPopup";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { getAllOverallPatient } from "../services/overall.service";
import { setManuallyClickComparison, setShouldReverseOverallColors } from "../store/reducers/comparisonReducers/comparison.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { OVERALL_CARDS_TYPE } from "../types/overall.type";
import { OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../types/pages.type";
import {
	getDynamicPercentageBy,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import { updateFilterListData } from "../utilis/overall-common";
import tooltipsInfo from "../utilis/tooltipContents";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { setOverallCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import { OVERALL_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";
import NoteContainer from "../components/shared/NoteContainer";

const DEFAULT_LIST = {
	ninetyDaysData: [],
	list: [],
};

const OverallPage = () => {
	const dispatch = useDispatch();
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [filterListData, setFilterListData] = useState(DEFAULT_OVERALL_DATA);
	const { overallCards } = useSelector((state) => state.customCard);
	const { saveCustomCardFilter } = useCustomCardFilter();
	const {
		filter,
		dbData,
		cardFilter,
		transferType,
		detailsDialog,
		isResetFilter,
		mainNumPercentage,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		lockedTotalBy,
		filterTotal,
	} = useSelector((state) => state.overall);
	const { percentageBy } = useSelector((state) => state.common);

	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);

	const { rangesSet, percentageThresholds, comparingAgainstDifferentFacility, shouldReverseOverallColors } =
		useSelector((state) => state.comparison);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		transferType: transferTypeComparison,
		patientList: patientListComparison,
		facilityPercentage: facilityPercentageComparison,
		lockedTotal: lockedTotalComparison,
		lockedByFacility: lockedByFacilityComparison,
		isCensusTotalLocked: isCensusTotalLockedComparison,
	} = useSelector((state) => state.overallComparison);
	const [projectionPopupOpen, setProjectionPopupOpen] = useState(false);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");

	// loading states
	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);
	const [isCardFilterEnabled, setIsCardFilterEnabled] = useState(false);

	const resetData = useCallback(() => {
		setPatientList([]);
		setFilterListData(DEFAULT_OVERALL_DATA);
		setLoading(false);
		dispatch(setDefaultData());
	}, [dispatch]);

	const { updateTotalToDBFilter: updateComparisonData } = useComparisonFunctions({
		forPage: "overall",
		setLoadingComparison,
	});

	useEffect(() => {
		if (isDateUpdated && filter && filter.startDate && activeFacilities.length > 0 && activeFacilityLocalStorage) {
			dispatch(setLockedByFacility([]));
			dispatch(setLockTotal(null));
			dispatch(setLockedTotalBy(null));
			// const rangeFilter = filter?.defaultComparison?.comparisonFilter ? filter.defaultComparison?.comparisonFilter : null;
			// const filterData = _.omit(filter, ["defaultComparison"]);
			getAllDataCount({ ...filter, facilityIds: activeFacilities });
			// if (rangeFilter) {
			// 	dispatch(setManuallyClickComparison({ range: rangeFilter, isDefault: true }));
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, filterComparison, activeFacilityLocalStorage]);

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter, mainNumPercentage, projectionDays]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		//dispatch(setIsMainCensusPercentage(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainNumPercentage, lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType, projectionDays]);

	const onStateUpdate = useCallback((value) => {
		setIsCardFilterEnabled(value)
	}, []);

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, res, cardFilter.priorityData, transferType, false);

		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
	};

	const updateTotalToDBFilterComparison = async (res) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			res,
			cardFilterComparison.priorityData,
			transferTypeComparison,
			true
		);

		setFilterListDataComparison((prevState) => {
			return { ...prevState, ...latestListData };
		});
	};

	const getAllDataCount = async (filter) => {
		setLoading(true);
		await Promise.all([
			getAllOverallPatient(filter, false, "main").then(async (res) => {
				if (res && res.list.length > 0) {
					setPatientList(res);
					await updateTotalToDBFilter(res);
				} else {
					resetData();
				}
			}),
			rangesSet
				? getAllOverallPatient({ ...filterComparison, facilitiyIds: activeFacilitiesComparison }, true, "comparison").then(
					async (res) => {
						if (res && res.list.length > 0) {
							dispatch(setPatientListComparison(res));
						} else {
							resetData();
						}
					}
				)
				: null,
		])
			.then(() => {
				setLoading(false);
			})
			.catch((e) => {
				if (e?.message !== "canceled") {
					setLoading(false);
				}
			});
	};

	const handleToggle = useCallback(
		async ({ item, type, cardTitle = null }) => {
			if (item) {
				dispatch(setIsMainCensusPercentage(null));
				const value = item._id;
				const filtersItem = cardFilter;
				const filterData = filtersItem[type];
				const currentIndex = filterData.indexOf(value);
				const newChecked = [...filterData];
				currentIndex === -1 ? newChecked.push(value) : newChecked.splice(currentIndex, 1);

				const [priorityData, priorityDataComparison] = await Promise.all([
					priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
					rangesSet
						? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle)
						: undefined,
				]);

				dispatch(setCardFilterOverall({ [type]: newChecked, priorityData: priorityData }));
				rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
				const [latestListData, latestListDataComparison] = await Promise.all([
					updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
					rangesSet &&
					updateFilterListData(
						{ ...cardFilterComparison, [type]: newChecked },
						patientListComparison,
						priorityDataComparison,
						transferTypeComparison,
						true
					),
				]);
				setFilterListData((prevState) => {
					return { ...prevState, ...latestListData };
				});
				if (rangesSet) {
					const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
					dispatch(setFilterListDataComparison(updatedFilterListData));
				}
			}
		},
		[
			dispatch,
			cardFilter,
			rangesSet,
			cardFilterComparison,
			patientList,
			transferType,
			patientListComparison,
			transferTypeComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);

		dispatch(setCardFilterOverall({ [type]: newChecked, priorityData: priorityData }));
		rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
			rangesSet &&
			updateFilterListData(
				{ ...cardFilterComparison, [type]: newChecked },
				patientListComparison,
				priorityDataComparison,
				transferTypeComparison,
				true
			),
		]);
		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
		if (rangesSet) {
			const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const getTotalCount = useCallback((dbData = null, withSign = true, debug = false) => {
		if (dbData) {
			const { totalOutgoing, totalIncoming } = dbData;
			const n = Number(totalIncoming) - Number(totalOutgoing);
			if (withSign) {
				return (n < 0 ? "-" : "+") + Math.abs(n);
			} else {
				return n;
			}
		}
	}, []);

	const handleOnclickCard = useCallback(
		async (value, totalOutgoing = null, shouldReverseColors) => {
			let transferTypeOverride = null;
			if (transferType && transferType === OVERALL_CARDS_TYPE.TOTAL && value === OVERALL_CARDS_TYPE.TOTAL) {
				console.log("already selected");
			} else {
				dispatch(setIsMainCensusPercentage(null));
				if (transferType && transferType === value) {
					transferTypeOverride = OVERALL_PAGE_SUB_TYPE.TOTAL;
					dispatch(setTransferTypeComparison(OVERALL_PAGE_SUB_TYPE.TOTAL));
					dispatch(setTransferType(OVERALL_PAGE_SUB_TYPE.TOTAL));
				} else {
					if (value === OVERALL_CARDS_TYPE.TOTAL_INCOMING || value === OVERALL_CARDS_TYPE.TOTAL) {
						dispatch(setCardFilterOverall({ ninetyDaysData: [] }));
						const idx = cardFilter.priorityData.findIndex((p) => p.type === OVERALL_CARDS_TYPE.NINETY_DAYS_DATA);
						if (idx !== -1) {
							let latestFilter = update(cardFilter.priorityData, {
								$splice: [[idx, 1]],
							});
							dispatch(setCardFilterOverall({ priorityData: latestFilter }));
						}
					}
					dispatch(setTransferTypeComparison(value));
					dispatch(setTransferType(value));
					transferTypeOverride = value;
				}
			}
			if (totalOutgoing) {
				dispatch(setMainTotal(totalOutgoing));
			}
			if (cardFilterComparison && cardFilterComparison.listData && transferTypeOverride) {
				await updateComparisonData(cardFilterComparison.listData?.data, null, transferTypeOverride);
			}
			shouldReverseColors
				? dispatch(setShouldReverseOverallColors(true))
				: dispatch(setShouldReverseOverallColors(false));
		},
		[transferType, cardFilterComparison, dispatch, cardFilter.priorityData, updateComparisonData]
	);

	// * added comparison
	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false) => {
			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);
			let filterSelected = mainNumPercentage
				? false
				: (cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== cardFilter.type) ||
				transferType != null;
			let data = [];
			if (
				type === OVERALL_CARDS_TYPE.TOTAL ||
				type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
				type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
			) {
				filterSelected = false;
				data = [];
			} else {
				data = filterListData[type];
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData: cardFilter[type] || [],
				type,
				filter,
				relation,
				transferType,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData
			};
			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
					dbData,
				})
			);

			// COMPARISON ANALOG ------------>
			let latestCardFilterComparison = await updateChartFilterByPriority(cardFilterComparison, type);
			let filterSelectedComparison = mainNumPercentage
				? false
				: (cardFilterComparison.priorityData.length > 0 &&
					cardFilterComparison.priorityData[0]["type"] !== cardFilterComparison.type) ||
				transferType != null;
			let dataComparison = [];
			if (
				type === OVERALL_CARDS_TYPE.TOTAL ||
				type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
				type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
			) {
				filterSelectedComparison = false;
				dataComparison = [];
			} else {
				dataComparison = filterListDataComparison ? filterListDataComparison[type] : [];
			}
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: cardFilterComparison[type] || [],
				type,
				filter: filterComparison,
				relation,
				transferType: transferTypeComparison,
				filterSelected: filterSelectedComparison,
				facilityPercentage: facilityPercentageComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: isCensusTotalLockedComparison,
			};
			dispatch(
				openDetailsDialogComparison({
					isOpen: true,
					title,
					type,
					data: dataComparison,
					filters: filtersComparison,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			filterListData,
			cardFilter,
			filter,
			filterListDataComparison,
			cardFilterComparison,
			filterComparison,
			mainNumPercentage,
		]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.OVERALL,
				filterListData,
				isTotalCard,
				automaticallyReport: automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				mainNumPercentage,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.OVERALL,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.OVERALL,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				tableFilterType: graphData?.tableFilterType,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.OVERALL,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...overallCards];
		if (key === "selectAll") {
			cards = _.map(OVERALL_CUSTOM_CARD, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setOverallCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.OVERALL, cards })
	}, [overallCards, dispatch, saveCustomCardFilter]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	const [transitionStyle, setTransitionStyle] = useState({
		transition: "all 0.8s ease",
	});

	return (
		<Page title="Dashboard: Overall Page">
			{detailsDialog.isOpen && (
				<OverallCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<OverallHeader
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							projectionSelectionBlock={<PickProjectionDaysPopup
								open={projectionPopupOpen}
								setOpen={setProjectionPopupOpen}
								loading={loading}
							/>}
						/>
					</div>
				</div>

				<NoteContainer page={PAGE_TYPE.OVERALL}>
					<Box sx={{ p: 2, mt: "90px" }}>
						{/* {rangesSet && !loading &&
							<Grid
								container
								display="flex"
								direction={"row"}
								sx={{ mb: "20px" }}
								justifyContent="flex-end"
							>
								<Grid item>
									<ColorInfoBox
										pageType={PAGE_TYPE.OVERALL}
										loading={loading || loadingComparison}
										style={{ position: "block", top: "0px" }}
									/>
								</Grid>
							</Grid>
						} */}
						<HospitalRowContainer spacing={2}>
							<Grid item md={rangesSet ? 3 : 4} lg={rangesSet ? 3 : 4} xs={12} sx={{ ...transitionStyle }}
							>
								<Grid container direction={"column"}>
									<Grid item xs={12}>
										<AddCardDrawer
											listData={OVERALL_CUSTOM_CARD}
											selectedCards={overallCards}
											handleAddCustomCard={handleAddCustomCard}
											page={PAGE_TYPE.OVERALL}
											sx={{
												width: "calc(100% - 9px)",
											}}
											scrollHeight={248}
											onStateUpdate={onStateUpdate}
											scrollTop={scrollPosition?.scrollTop}
										/>
									</Grid>
									<Collapse
										in={!isCardFilterEnabled}
										timeout={1000}
									// easing={{
									// 	enter: "cubic-bezier(0, 1.5, .8, 1)",
									// 	exit: "linear"
									// }}
									>
										<Grid item xs={12}>
											{/* <Box sx={{ height: '200px', backgroundColor: 'lightblue' }}> */}
											<SelectableCard
												title="Total +/-"
												disabled={transferType !== OVERALL_PAGE_SUB_TYPE.TOTAL && loading}
												selected={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL && !loading}
												page={PAGE_TYPE.OVERALL}
												tooltipContent={tooltipsInfo.overallPage["+/-"]}
												handleOnclickCard={() => handleOnclickCard(OVERALL_PAGE_SUB_TYPE.TOTAL, null, true)}
												handleOnClickAction={(title) => {
													if (transferType === OVERALL_CARDS_TYPE.TOTAL) {
														handleChartDialog(title, OVERALL_CARDS_TYPE.TOTAL, null, true);
													}
												}}
												loading={loading || loadingComparison}
											>
												<TotalOverallCard
													page={PAGE_TYPE.OVERALL}
													total={getTotalCount(dbData)}
													totalComparison={getTotalCount(dbDataComparison)}
													isProjection={true}
													filter={filter}
													filterComparison={filterComparison}
													subTitleText="30 Day Transfer Rate"
													totalProjection={projectionPerMonth(getTotalCount(dbData, false), filter)}
													totalWithOutSign={getTotalCount(dbData, false)}
													isCompareColor={rangesSet}
													averageCensus={percentageAgainst}
													averageCensusComparison={dbDataComparison?.censusAverage}
													loading={loading || loadingComparison}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													type="total"
												/>
											</SelectableCard>
											{/* </Box> */}
										</Grid>
									</Collapse>
								</Grid>
							</Grid>
							<Grid item md={rangesSet ? 3 : 4} lg={rangesSet ? 3 : 4} xs={12} sx={{ ...transitionStyle }}
							>
								<SelectableCard
									title="Total Outgoing Transfers"
									disabled={transferType !== OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING && loading}
									selected={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING && !loading}
									handleOnclickCard={() => handleOnclickCard(OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING, dbData.totalOutgoing)}
									handleOnClickAction={(title) => {
										if (transferType === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
											handleChartDialog(title, OVERALL_CARDS_TYPE.TOTAL_OUTGOING, null, true);
										}
									}}
									page={PAGE_TYPE.OVERALL}
									tooltipContent={tooltipsInfo.overallPage.totalOutgoing}
									loading={loading || loadingComparison}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING, title, true, otherData)
									}
								>
									<TotalOverallOutGoingCard
										totalComparisonPercentage={itemPercentage(
											dbDataComparison?.totalOutgoing,
											dbDataComparison?.censusAverage,
											"number"
										)}
										isProjection={true}
										page={PAGE_TYPE.OVERALL}
										total={dbData.totalOutgoing}
										totalComparison={dbDataComparison?.totalOutgoing}
										outGoingDetailsTotal={dbData.outGoingDetailsTotal}
										totalProjection={projectionPerMonth(dbData.totalOutgoing, filter)}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.totalOutgoing, filter),
											percentageAgainst
										)}
										loading={loading || loadingComparison}
										filter={filter}
										filterComparison={filterComparison}
										isCompareColor={rangesSet}
										averageCensus={percentageAgainst}
										averageCensusComparison={dbDataComparison?.censusAverage}
										projectionDays={projectionDays}
										filterTotal={filterTotal}
									/>
								</SelectableCard>
							</Grid>
							<Grid item md={rangesSet ? 3 : 4} lg={rangesSet ? 3 : 4} xs={12} sx={{ ...transitionStyle }}>
								<SelectableCard
									title="Total Incoming Transfers"
									disabled={transferType !== OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING && loading}
									selected={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING && !loading}
									handleOnclickCard={() => handleOnclickCard(OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING, null, true)}
									handleOnClickAction={(title) => {
										if (transferType === OVERALL_CARDS_TYPE.TOTAL_INCOMING) {
											handleChartDialog(title, OVERALL_CARDS_TYPE.TOTAL_INCOMING, null, true);
										}
									}}
									page={PAGE_TYPE.OVERALL}
									tooltipContent={tooltipsInfo.overallPage.totalIncoming}
									loading={loading || loadingComparison}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING, title, true, otherData)
									}
								>
									<TotalOverallIncomingCard
										totalComparisonPercentage={itemPercentage(
											dbDataComparison?.totalIncoming,
											dbDataComparison?.censusAverage,
											"number"
										)}
										isProjection={true}
										page={PAGE_TYPE.OVERALL}
										total={dbData.totalIncoming}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										totalComparison={dbDataComparison?.totalIncoming}
										totalProjection={projectionPerMonth(dbData.totalIncoming, filter)}
										incomingDetailsTotal={dbData.incomingDetailsTotal}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.totalIncoming, filter),
											percentageAgainst
										)}
										averageCensus={percentageAgainst}
										averageCensusComparison={dbDataComparison?.censusAverage}
										isCompareColor={rangesSet}
										projectionDays={projectionDays}
										filterTotal={filterTotal}
									/>
								</SelectableCard>
							</Grid>
							{rangesSet && (

								<Grid item md={rangesSet ? 3 : 4} lg={rangesSet ? 3 : 4} xs={12} sx={{ ...transitionStyle }}>
									<ColorInfoBox
										loading={loading || loadingComparison}
										pageType={PAGE_TYPE.OVERALL}
									/>
								</Grid>

							)}
						</HospitalRowContainer>

						<HospitalRowContainer spacing={2}>
							<CardAnimation id={`${OVERALL_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(overallCards, OVERALL_CARDS_TYPE.DOCTOR_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.DOCTOR_DATA)}
										title="Per Doctor"
										minimizable={false}
										handleOnClickAction={(title) =>
											handleChartDialog(title, OVERALL_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR)
										}
										taggedList={
											<TaggedList
												type={"doctorData"}
												data={filterListData.doctorData}
												selectedItem={cardFilter.doctorData}
											/>
										}
										tooltipContent={tooltipsInfo.overallPage.perDoctor}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(OVERALL_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
										}
										secondaryTitleError={
											comparingAgainstDifferentFacility &&
											rangesSet &&
											"Trend Analysis disabled when comparing to other facilities"
										}
										secondaryTitleClassName="error"
										selectedItems={cardFilter.doctorData}
										data={filterListData.doctorData}
										type={"doctorData"}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<DoctorCard
											cardTitle="Per Doctor"
											data={filterListData.doctorData}
											minimizable={true}
											handleToggle={handleToggle}
											selectedItem={cardFilter.doctorData}
											comparisonData={filterListDataComparison?.doctorData}
											type={"doctorData"}
											loading={loading || loadingComparison}
											page={PAGE_TYPE.OVERALL}
											isCompareColor={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL}
											isPercentageShow={
												transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
													transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING
													? true
													: false
											}
											filter={filter}
											filterComparison={filterComparison}
											averageCensusComparison={dbDataComparison?.censusAverage}
											averageCensus={percentageAgainst}
											reverseColors={shouldReverseOverallColors}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.DOCTOR_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${OVERALL_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(overallCards, OVERALL_CARDS_TYPE.FLOORS_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.FLOORS_DATA)}
										title="Per Floor"
										minimizable={false}
										handleOnClickAction={(title) =>
											handleChartDialog(title, OVERALL_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT)
										}
										taggedList={
											<TaggedList
												type={"floorsData"}
												data={filterListData.floorsData}
												selectedItem={cardFilter.floorsData}
											/>
										}
										tooltipContent={tooltipsInfo.overallPage.perFloor}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(OVERALL_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
										}
										secondaryTitleError={
											comparingAgainstDifferentFacility &&
											rangesSet &&
											"Trend Analysis disabled when comparing to other facilities"
										}
										secondaryTitleClassName="error"
										selectedItems={cardFilter.floorsData}
										data={filterListData.floorsData}
										type={"floorsData"}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<FloorCardList
											cardTitle="Per Floor"
											isCompareColor={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL}
											data={filterListData.floorsData}
											dataComparison={filterListDataComparison?.floorsData}
											handleToggle={handleToggle}
											selectedItem={cardFilter.floorsData}
											type={"floorsData"}
											filter={filter}
											loading={loading || loadingComparison}
											filterComparison={filterComparison}
											page={PAGE_TYPE.OVERALL}
											isPercentageShow={
												transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
													transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING
													? true
													: false
											}
											averageCensusComparison={dbDataComparison?.censusAverage}
											averageCensus={percentageAgainst}
											reverseColors={shouldReverseOverallColors}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.FLOORS_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${OVERALL_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(overallCards, OVERALL_CARDS_TYPE.INSURANCE_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.INSURANCE_DATA)}
										title="Per Insurance"
										itemAlign={"ais"}
										handleOnClickAction={(title) =>
											handleChartDialog(title, OVERALL_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE)
										}
										tooltipContent={tooltipsInfo.overallPage.perInsurance}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(OVERALL_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
										}
										selectedItems={cardFilter.insuranceData}
										data={filterListData.insuranceData}
										type={"insuranceData"}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<InsuranceCard
											cardTitle="Per Insurance"
											isCompareColor={transferType === OVERALL_PAGE_SUB_TYPE.TOTAL}
											height={350}
											data={filterListData.insuranceData}
											filter={filter}
											dataComparison={filterListDataComparison?.insuranceData}
											handleToggle={handleToggle}
											selectedItem={cardFilter.insuranceData}
											type={"insuranceData"}
											loading={loading || loadingComparison}
											page={PAGE_TYPE.OVERALL}
											filterComparison={filterComparison}
											averageCensus={percentageAgainst}
											averageCensusComparison={dbDataComparison?.censusAverage}
											reverseColors={shouldReverseOverallColors}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.INSURANCE_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${OVERALL_CARDS_TYPE.NINETY_DAYS_DATA}_scroll`} checked={_.includes(overallCards, OVERALL_CARDS_TYPE.NINETY_DAYS_DATA)}>
								<Grid item xs={12}>
									<Card
										loading={loading || loadingComparison}
										priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.NINETY_DAYS_DATA)}
										minimizable={true}
										isDisabled={transferType !== OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING}
										isDetailsOpen={true}
										title="90 Days Analysis"
										handleOnClickAction={(title) => handleChartDialog(title, OVERALL_CARDS_TYPE.NINETY_DAYS_DATA)}
										tooltipContent={tooltipsInfo.overallPage.ninetyDaysAnalisys}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(OVERALL_CARDS_TYPE.NINETY_DAYS_DATA, title, false, otherData)
										}
									>
										<NinetyDaysAnalysis
											cardTitle="90 Days Analysis"
											height={"360px"}
											handleToggle={handleToggle}
											selectedItem={cardFilter.ninetyDaysData}
											type={"ninetyDaysData"}
											data={filterListData.ninetyDaysData}
											page={PAGE_TYPE.OVERALL}
											comparisonData={filterListDataComparison?.ninetyDaysData}
											averageCensusComparison={dbDataComparison?.censusAverage}
											averageCensus={percentageAgainst}
											filter={filter}
											loading={loading || loadingComparison}
											filterComparison={filterComparison}
											reverseColors={shouldReverseOverallColors}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(OVERALL_CARDS_TYPE.NINETY_DAYS_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>
						</HospitalRowContainer>
					</Box>
				</NoteContainer>
				<ShadowLineWithText data={overallCards} />
			</div>
		</Page>
	);
};

export default OverallPage;
