import { Paper, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import classNames from "classnames";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import styles from "./HospitalList.module.scss";
import HospitalListSkeleton from "./HospitalListSkeleton";

const HospitalList = ({
	data,
	dataComparison,
	filter,
	filterComparison,
	handleToggle,
	selectedItem = [],
	type,
	page,
	cardTitle,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	reverseColorsAdmissionPage,
	comparingAgainstDifferentFacility,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
	searchValue,
	selectAll,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const isFirstItemInPriorityData = priorityNumber === 1;

	return (
		<Paper style={{ minHeight: 350, height: 350, overflow: "auto", width: "100%" }}>
			<div className={`${styles.HospitalList} `}>
				<List
					sx={{
						width: "100%",
						bgcolor: "background.paper",
					}}
					dense={true}
					disablePadding={true}
				>
					{loading ? (
						<HospitalListSkeleton />
					) : (
						data.length > 0 &&
						data
							.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
							.map((item, index) => {
								const ID = item._id;
								const labelId = `checkbox-list-label-${ID}`;
								const selected = selectedItem.indexOf(ID) !== -1;
								
								const itemComparison = dataComparison?.find((x) => x._id === item._id);
								
								const percentage = itemPercentage(item.total, null, "number");
								const percentageComparison = itemPercentage(itemComparison?.total, null, "number");
								let comparisonColor;
								let comparingAgainst;
								let comparingAgainstScaled;
								let numberOfDays;

								if (page === PAGE_TYPE.ADMISSION) {
									const percentageAdmissions = itemPercentage(item?.total || 0, admissionCompareAgainst, "number");

									const percentageAdmissionsComparison = itemPercentage(
										itemComparison?.total || 0,
										admissionCompareAgainstComparison,
										"number"
									);

									const {
										percentageDiff,
										itemTotalComparisonScaled,
										numberOfDays: numberOfDaysTemp,
									} = calculateDiffBetweenPercentages(
										percentageAdmissions,
										percentageAdmissionsComparison,
										reverseColorsAdmissionPage,
										filter,
										filterComparison,
										itemComparison?.total || 0,
										projectionDays,
										true
									);
									numberOfDays = numberOfDaysTemp;
									comparingAgainstScaled = itemTotalComparisonScaled;
									comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
									comparingAgainst = itemComparison?.total;

								} else {
									const {
										percentageDiff,
										itemTotalComparisonScaled,
										numberOfDays: numberOfDaysTemp,
									} = calculateDiffBetweenPercentages(
										percentage,
										percentageComparison,
										false,
										filter,
										filterComparison,
										itemComparison?.total,
										projectionDays
									);

									numberOfDays = numberOfDaysTemp;
									comparingAgainstScaled = itemTotalComparisonScaled;
									comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
									comparingAgainst = itemComparison?.total;
								}

								if (comparingAgainstDifferentFacility) {
									comparisonColor = null;
								}

								return (
									<ListItem
										sx={{ padding: "5px" }}
										key={ID}
										disablePadding
										className={classNames(styles.listItem, selected && styles.selected)}
									>
										<ListItemButton
											sx={{
												boxShadow: "0px 0px 14px 0px rgb(0 0 0 / 8%)",
												borderRadius: "8px",
											}}
											role={undefined}
											onClick={(e) => handleToggle({ item, itemComparison, type, cardTitle, isChecked: selected })}
											dense
											className={styles.listItemButton}
										>
											<Stack
												direction={"row"}
												sx={{
													width: "100%",
													paddingTop: "15px",
													paddingBottom: "10px",
												}}
											>
												<Stack alignItems={"flex-start"} sx={{ position: "absolute", left: "-10px", top: "-10px" }}>
													<Checkbox
														edge="start"
														checked={selected}
														tabIndex={-1}
														disableRipple
														inputProps={{ "aria-labelledby": labelId }}
														className={styles.dayCardCheckbox}
													/>
												</Stack>
												<Stack
													sx={{ width: "100%" }}
													direction="row"
													alignItems={"center"}
													justifyContent="space-between"
												>
													<CardItemTooltip item={item}>
														<div className={`${styles.title}`}>{item.label}</div>
													</CardItemTooltip>
													<div className={`${styles.rightContainer}`}>
														{!loading && (
															<ColorBox
																color={comparisonColor}
																type="list"
																sx={{ mr: "5px" }}
																comparingAgainst={comparingAgainst}
																comparingAgainstScaled={comparingAgainstScaled}
																numberOfDays={numberOfDays}
															/>
														)}
														<span className={classNames("ffmar-bold fs16", styles.totalText)}>
															{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}
														</span>
														<span className="ffmar-reg fs16">
															&nbsp; (
															{calcProPercentsBasedOnFilterAndDays(
																item.percentage ? item.percentage : itemPercentage(item.total, null, null, page),
																filter,
																projectionDays,
																true,
																isComparingAgainstAvgCensus ||
																	lockedTotalBy === "census" ||
																	(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															)}
															%)
														</span>
													</div>
												</Stack>
											</Stack>
										</ListItemButton>
									</ListItem>
								);
							})
					)}
					<NoRecordFound data={data} />
				</List>
			</div>
		</Paper>
	);
};

export default HospitalList;
