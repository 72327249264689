import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Button,
    List,
    ListItem,
    IconButton,
    Typography,
    TextareaAutosize,
    Divider,
    Tooltip,
    Menu,
    MenuItem,
    Popover,
} from "@mui/material";
import {
    Delete,
    Edit,
    Share,
    Close,
    FilterList,
    AspectRatio,
} from "@mui/icons-material"; // Added ArrowDropDown and ArrowDropUp
import { Formik, Form } from "formik";
import {
    setIsNoteExpanded,
    setIsOpenNote,
} from "../../../store/reducers/common.slice";
import { useDispatch, useSelector } from "react-redux";
import {
    addNote,
    clearNotifications,
    deleteNote,
    getNotes,
    updateNote,
} from "../../../services/note.service";
import { LoadingButton } from "@mui/lab";
import ShareNotesDialog from "./ShareNotesDialog";
import axios from "../../../axios";
import { ADD_NOTIFICATION } from "../../../store/types";

const TruncatedTextWithTooltip = ({ text }) => {
    const shouldShowTooltip = text.length > 13;

    return (
        <Tooltip
            title={shouldShowTooltip ? text : ""}
            disableHoverListener={!shouldShowTooltip}
            arrow
        >
            <Box
                sx={{
                    maxWidth: "130px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    ...shouldShowTooltip && { cursor: "pointer" }
                }}
            >
                <Typography noWrap>{text}</Typography>
            </Box>
        </Tooltip>
    );
};

// Define the PAGE_TYPE constants
export const PAGE_TYPE = {
    COMMUNITY_TRANSFER: "communityTransfer",
    ADMISSION: "admission",
    HOSPITAL: "hospital",
    DECEASED: "deceased",
    OVERALL: "overall",
};

const NotesList = ({ page, openFrom }) => {
    const [isAdding, setIsAdding] = useState(false); // Toggle form visibility
    const [editingIndex, setEditingIndex] = useState(null); // Track the note being edited
    const [selectedPage, setSelectedPage] = useState(null); // Default page filter set to null
    const [filterVisible, setFilterVisible] = useState(false); // Toggle filter menu visibility
    const [anchorEl, setAnchorEl] = useState(null); // For managing the menu anchor element
    // eslint-disable-next-line no-unused-vars
    const [expanded, setExpanded] = useState(false); // Track the expanded state
    const dispatch = useDispatch();
    const { notes, loading } = useSelector((state) => state.note);
    const [isShare, setIsShare] = useState(null); // Toggle form visibility
    const [isFocused, setIsFocused] = useState(false); // Track focus state

    // Confirmation popover states
    const [anchorElDelete, setAnchorElDelete] = useState(null); // Anchor for delete popover
    const [noteToDelete, setNoteToDelete] = useState(null); // The note being deleted

    // Fetch all notes
    useEffect(() => {
        dispatch(getNotes());
        return () => dispatch(clearNotifications());
    }, [dispatch]);

    // Filter notes based on selected page
    const filteredNotes = selectedPage
        ? notes?.filter((note) => note.page === selectedPage)
        : notes;

    // Add or update note handler
    const handleAddNote = useCallback(
        (values, { resetForm, setFieldValue }, saveAndNew = false) => {
            if (editingIndex !== null) {
                const note = notes?.find((note) => note._id === editingIndex);
                dispatch(updateNote({ ...note, ...values }));
                setEditingIndex(null);
            } else {
                dispatch(addNote({ ...values, page: page }));
            }

            // Reset the form and handle the next action based on `saveAndNew`
            if (saveAndNew) {
                resetForm();
                setFieldValue("description", ""); // Clear description field for new note
            } else {
                resetForm();
                setFieldValue("description", "");
                setIsAdding(false); // Close form after saving
            }
        },
        [dispatch, editingIndex, notes, page]
    );

    // Open edit form for an existing note
    const handleEditNote = (noteId) => {
        setEditingIndex(noteId);
        setIsAdding(true);
    };

    // Close note form
    const handleClose = useCallback(() => {
        dispatch(setIsOpenNote(false));
    }, [dispatch]);

    // Open filter menu
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterVisible(true);
    };

    // Close filter menu
    const handleMenuClose = () => {
        setFilterVisible(false);
        setAnchorEl(null);
    };

    // Handle filter selection
    const handleFilterSelect = (page) => {
        setSelectedPage(page);
        handleMenuClose(); // Close the menu after selecting a filter
    };

    const handleExpandClick = () => {
        dispatch(setIsNoteExpanded());
    };

    const handleShareClick = useCallback((noteId) => {
        setIsShare(noteId);
    }, []);

    const [users, setUsers] = useState([]);

    const getUsers = async () => {
        let re = await axios
            .get(`/api/user/notes/${localStorage.getItem("accountId")}`)
            .catch((e) => e);
        setUsers(re.data || []);
    };

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShareNotes = async (values) => {
        console.log(values, "values");
        let re = await axios.post(`/api/note/share`, values).catch((e) => e);
        console.log(re, "re");
        if (re) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: "Note share successfully!",
                    id: "noteShare",
                },
            });
            setIsShare(null);
        }
    };

    const handleDeleteNote = () => {
        dispatch(deleteNote(noteToDelete));
        setAnchorElDelete(null); // Close the popover after deletion
        setNoteToDelete(null); // Reset the noteToDelete state
    };

    const handleOpenDeletePopover = (event, noteId) => {
        setAnchorElDelete(event.currentTarget); // Set the anchor for the popover
        setNoteToDelete(noteId); // Set the note ID to be deleted
    };

    const handleCloseDeletePopover = () => {
        setAnchorElDelete(null); // Close the popover
        setNoteToDelete(null); // Reset the noteToDelete state
    };

    return (
        <Box padding={0}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
            >
                <Typography variant="h4" gutterBottom>
                    Notes
                </Typography>
                <Box>
                    {/* Expand Button */}
                    <IconButton
                        color="default"
                        onClick={handleExpandClick} // Toggle expanded state
                    >
                        {expanded ? <AspectRatio /> : <AspectRatio />}{" "}
                        {/* Show ArrowDropUp when expanded, otherwise ArrowDropDown */}
                    </IconButton>

                    {/* Close Button */}
                    <IconButton color="error" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} /> {/* Divider below Title */}
            {/* Add Note Button and Filter Icon */}
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                {/* Add Note Button */}
                {!isAdding && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsAdding(true)}
                    >
                        Add Note
                    </Button>
                )}

                {/* Filter Icon */}
                {!isAdding && (
                    <Box display="flex" alignItems="center">
                        <Tooltip title="Filter Notes" arrow>
                            <IconButton
                                size="small"
                                color="default"
                                onClick={handleFilterClick} // Open the filter menu
                                sx={{ marginLeft: 2 }}
                            >
                                <FilterList />
                            </IconButton>
                        </Tooltip>
                        {/* Filter Menu */}
                        <Menu
                            anchorEl={anchorEl}
                            open={filterVisible}
                            onClose={handleMenuClose} // Close the menu
                        >
                            <MenuItem
                                onClick={() => handleFilterSelect(null)}
                                selected={selectedPage === null} // Highlight 'All Pages' when selected
                            >
                                All Pages
                            </MenuItem>
                            {Object.values(PAGE_TYPE).map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={() => handleFilterSelect(page)}
                                    selected={selectedPage === page} // Highlight the selected page
                                >
                                    {page
                                        .replace(/([A-Z])/g, " $1")
                                        .replace(/^./, (str) => str.toUpperCase())}{" "}
                                    {/* Formatting the page name */}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                )}
            </Box>
            {/* Form for Adding/Editing Notes */}
            {isAdding && (
                <Formik
                    initialValues={{
                        description:
                            editingIndex !== null
                                ? notes?.find((note) => note._id === editingIndex)?.description
                                : "",
                    }}
                    onSubmit={(values, { resetForm, setFieldValue }) => {
                        handleAddNote(values, { resetForm, setFieldValue });
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        resetForm,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit} style={{ height: "100vh" }}>
                            <Box
                                sx={{
                                    height:
                                        openFrom === "page"
                                            ? "calc(100vh - 270px)"
                                            : "calc(100vh - 320px)",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {/* TextArea for Note Input */}
                                <TextareaAutosize
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    placeholder="Enter your note"
                                    minRows={4}
                                    style={{
                                        flexGrow: 1,
                                        padding: "8px",
                                        fontSize: "16px",
                                        borderRadius: "4px",
                                        fontFamily: "mont reg",
                                        border: `1px solid ${isFocused ? "#1976d2" : "#ccc"}`, // Highlight border when focused
                                        boxSizing: "border-box",
                                        resize: "none",
                                        outline: "none", // Remove default outline
                                        transition: "border-color 0.3s ease", // Smooth border-color transition
                                    }}
                                    onFocus={() => setIsFocused(true)} // Set focus state
                                    onBlur={() => setIsFocused(false)} // Reset focus state
                                />
                                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                                    {/* Save & New Button */}
                                    {/* {!editingIndex && (
                    <LoadingButton
                      loading={loading}
                      type="button"
                      variant="contained"
                      color="info"
                      size="small"
                      sx={{ marginRight: 2 }}
                      onClick={() => handleAddNote(values, { resetForm, setFieldValue }, true)}
                    >
                      Save & New Note
                    </LoadingButton>
                  )} */}

                                    {/* Save or Update Button */}
                                    <LoadingButton
                                        loading={loading}
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        sx={{ marginRight: 2 }}
                                    >
                                        {editingIndex !== null ? "Update Note" : "Save Note"}
                                    </LoadingButton>

                                    {/* Cancel Button */}
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            setIsAdding(false);
                                            setEditingIndex(null);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
            {/* Notes List */}
            {!isAdding && (
                <Box
                    // sx={{
                    //     maxHeight:
                    //         openFrom === "page"
                    //             ? "calc(100vh - 300px)"
                    //             : "calc(100vh - 355px)",
                    //     overflowY: "auto",
                    // }}
                >
                    {filteredNotes?.length === 0 ? ( // Check if there are no notes to display
                        <Typography variant="h6" color="textSecondary">
                            No Notes Found
                        </Typography>
                    ) : (
                        <List>
                            {filteredNotes?.map((note, index) => (
                                <ListItem
                                    key={index}
                                    divider
                                    secondaryAction={
                                        <>
                                            {/* Edit Icon with Tooltip */}
                                            <Tooltip title="Edit Note" arrow>
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => handleEditNote(note?._id)}
                                                >
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Share Note" arrow>
                                                {/* Share Button */}
                                                <IconButton
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleShareClick(note?._id)}
                                                >
                                                    <Share fontSize="small" />
                                                </IconButton>
                                            </Tooltip>

                                            {/* Delete Icon with Tooltip */}
                                            <Tooltip title="Delete Note" arrow>
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                    color="error"
                                                    onClick={(event) =>
                                                        handleOpenDeletePopover(event, note?._id)
                                                    }
                                                >
                                                    <Delete fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <TruncatedTextWithTooltip text={note?.description} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            )}
            <Popover
                open={Boolean(anchorElDelete)}
                anchorEl={anchorElDelete}
                onClose={handleCloseDeletePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography variant="body1">
                        Are you sure you want to delete this note?
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" marginTop={1}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteNote}
                            sx={{ mr: 2 }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCloseDeletePopover}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Popover>
            {!!isShare && (
                <ShareNotesDialog
                    handleClose={() => setIsShare(null)}
                    handleSubmit={handleShareNotes}
                    isShare={!!isShare}
                    noteId={isShare}
                    users={users}
                />
            )}
        </Box>
    );
};

export default NotesList;
