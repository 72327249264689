// types
import { createSlice } from "@reduxjs/toolkit";
import { percentageByEnum } from "../../data/common.data";

// initial state
const initialState = {
	isFacilityLoaded: false,
	isFilterDateUpdate: true,
	lastADTDate: null,
	isCensusDataLoading: false,
	selectedAccount: null,
	projectionDays: null,
	customPercentage: null,
	customPercentageLabel: null,
	percentageBy: percentageByEnum.censusAverage,
	customePercentages: [],
	isScrollHightLight: null,
	endDateOfADT: null,
	isProjectionPopup: false,
	isNotePopup: false,
	isNoteExpanded: false,
	selectedShortcutOptions: {
		isCompare: false,
		isAutoReport: false,
		isAlertsReport: false,
		isPickProjectionDays: false,
		isNotes: false
	}
};

// ==============================|| SLICE - common ||============================== //

const common = createSlice({
	name: "common",
	initialState,
	reducers: {
		setIsNoteExpanded(state, action) {
			state.isNoteExpanded = !state.isNoteExpanded;
		},
		setIsOpenNote(state, action) {
			state.isNotePopup = action.payload;
		},			
		setSelectedShortcutOptions(state, action) {
			state.selectedShortcutOptions = action.payload;
		},
		setIsProjectionsPopup(state, action) {
			state.isProjectionPopup = action.payload;
		},
		setEndDateOfADT(state, action) {
			state.endDateOfADT = action.payload;
		},
		setIsScrollHightLight(state, action) {
			state.isScrollHightLight = action.payload;
		},
		setCustomePercentages(state, action) {
			state.customePercentages = action.payload;
		},
		setFacilityLoaded(state, action) {
			state.isFacilityLoaded = action.payload;
		},
		setLastADTDate(state, action) {
			state.lastADTDate = action.payload;
		},
		setFilterDateUpdate(state, action) {
			state.isFilterDateUpdate = action.payload;
		},
		setCensusDataLoading(state, action) {
			state.isCensusDataLoading = action.payload;
		},
		setProjectionDays(state, action) {
			state.projectionDays = action.payload;
		},
		setSelectedAccount(state, action) {
			state.selectedAccount = action.payload;
		},
		setCustomPercentage(state, action) {
			state.customPercentage = action.payload;
		},
		setCustomPercentageLabel(state, action) {
			state.customPercentageLabel = action.payload;
		},
		setPercentageBy(state, action) {
			state.percentageBy = action.payload;
		},
	},
});

export default common.reducer;

export const {
	setCustomPercentageLabel,
	setPercentageBy,
	setPercentageAgainst,
	setCustomPercentage,
	setCensusDataLoading,
	setProjectionDays,
	setLastADTDate,
	setFilterDateUpdate,
	setFacilityLoaded,
	setSelectedAccount,
	setCustomePercentages,
	setIsScrollHightLight,
	setEndDateOfADT,
	setIsProjectionsPopup,
	setSelectedShortcutOptions,
	setIsOpenNote,	
	setIsNoteExpanded
} = common.actions;
