import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createNoteData, deleteNoteData, getNotesData } from "../../../adt-tech-fe/src/services/api/note.api";

// Async actions
export const addNote = createAsyncThunk(
    "note/addNote",
    async (body, { rejectWithValue }) => {
        try {
            const response = await createNoteData(body);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "Error while adding note.");
        }
    }
);

export const updateNote = createAsyncThunk(
    "note/updateNote",
    async (body, { rejectWithValue }) => {
        try {
            const response = await createNoteData(body);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "Error while updating note.");
        }
    }
);

export const getNotes = createAsyncThunk(
    "note/getNotes",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getNotesData(params);

            return response;
        } catch (error) {
            return rejectWithValue("Error while fetching notes.");
        }
    }
);

export const deleteNote = createAsyncThunk(
    "note/deleteNote",
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteNoteData(id);
            return { id, response };
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "Error while deleting note.");
        }
    }
);

// Slice
const noteSlice = createSlice({
    name: "note",
    initialState: {
        notes: [],
        loading: false,
        error: null,
        notifications: [],
    },
    reducers: {
        clearNotifications: (state) => {
            state.notifications = [];
        },
    },
    extraReducers: (builder) => {
        builder
            // Add Note
            .addCase(addNote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addNote.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.notifications.push({
                    type: "success",
                    label: "Note added successfully",
                });
                state.notes.push(payload?.data); // Assuming payload contains the note object
            })
            .addCase(addNote.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.notifications.push({
                    type: "error",
                    label: payload,
                });
            })
            // Update Note
            .addCase(updateNote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateNote.fulfilled, (state, {payload}) => {
                
                state.loading = false;
                state.notifications.push({
                    type: "success",
                    label: "Note updated successfully",
                });
                const index = state.notes.findIndex((note) => note._id === payload?.data._id);
                if (index !== -1) {
                    state.notes[index] = payload?.data;
                    
                }
            })
            .addCase(updateNote.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.notifications.push({
                    type: "error",
                    label: payload,
                });
            })
            // Get Notes
            .addCase(getNotes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getNotes.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.notes = payload;
            })
            .addCase(getNotes.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            // Delete Note
            .addCase(deleteNote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteNote.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.notifications.push({
                    type: "success",
                    label: "Note deleted successfully",
                });
                
                state.notes = state.notes.filter((note) => note._id !== payload.id);
            })
            .addCase(deleteNote.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.notifications.push({
                    type: "error",
                    label: payload,
                });
            });
    },
});

// Export actions and reducer
export const { clearNotifications } = noteSlice.actions;
export default noteSlice.reducer;
