import api from './api';

const API_PREFIX = "api/facility";

const getAccountFacilityData = async (params) => {
    const response = await api.get(`${API_PREFIX}/list/all/account`, { params });
    return response;
};


const getFacilityOptionData = async () => {
    const response = await api.get(`api/user/facilities/options`);
    return response;
};

const getManageFacilitiesData = async (params) => {
    const response = await api.get(`${API_PREFIX}/manage-facility-list`, { params });
    return response;
};

const saveUser = async (body) => {
    const response = await api.post(`api/user`, body);
    return response;
};

const updateUser = async (id, body) => {
    const response = await api.put(`api/user/${id}`, body);
    return response;
};

const updateUserProfile = async (body) => {
    const response = await api.post(`api/user/update-profile`, body);
    return response;
};

const changeUserPassword = async (id, body) => {
    const response = await api.post(`api/user/change-password`, body);
    return response;
};

const forgotPassword = async (body) => {
    const response = await api.post(`api/user/forgot-password`, body);
    return response;
};

const saveNewPassword = async (body) => {
    const response = await api.post(`api/user/new-password`, body);
    return response;
};

const updateFirstPassword = async (authId, body) => {
    const response = await api.post(`api/user/update-password`, body);
    return response;
};

const userLogin = async (body) => {
    const response = await api.post(`api/user/login`, body);
    return response;
};


const getAccountQuestions = async (params) => {
    const response = await api.get(`/api/question/table-columns`, { params });
    return response;
};

const getUser = async () => {
    const response = await api.get(`api/user`);
    return response;
};

const getVersion  = async () => {
    const response = await api.get(`api/version`);
    return response;
};

export {
    userLogin,
    getAccountFacilityData,
    getFacilityOptionData,
    getManageFacilitiesData,
    saveUser,
    updateUser,
    updateUserProfile,
    changeUserPassword,
    forgotPassword,
    saveNewPassword,
    updateFirstPassword,
    getAccountQuestions,
    getUser,
    getVersion
};
