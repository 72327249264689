import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { getVersion } from "../services/api/user.api";

const UI_VERSION = "1.0.0"; // Update this manually on each deploy

const VersionPage = () => {
    const [apiVersion, setApiVersion] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch API version
        const fetchApiVersion = async () => {
            try {
                const response = await getVersion(); // Update with your actual API endpoint
                setApiVersion(response); // Assumes the API responds with { version: "1.0.0" }
            } catch (error) {
                console.error("Error fetching API version:", error);
                setApiVersion("Error fetching API version");
            } finally {
                setLoading(false);
            }
        };

        fetchApiVersion();
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <Typography variant="h4" gutterBottom>
                Application Version Information
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <Box textAlign="center">                    
                    <Typography variant="h6">
                        <strong>UI Version:</strong> {UI_VERSION}
                    </Typography>
                    <Typography variant="h6">
                        <strong>API Version:</strong> {apiVersion}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default VersionPage;