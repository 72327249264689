import * as React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
	getReportSubscription,
	removeReportSubscription,
	updateReportSubscription,
} from "../../../services/report-subscription.service";
import { dateFormat } from "../../../utilis/date-formats";
import { useDispatch } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import NoRecordFound from "../NoRecordFound";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { AUTOMATICALLY_REPORT_TYPE } from "../../../types/report.type";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";

export default function AutomaticallyReport({
	isAutomaticReportSaved,
	page,
	isOpenAutoReport,
	type = "default",
	setIsOpenAutoReport,
	selectedSortCut
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const [data, setData] = React.useState([]);
	const dispatch = useDispatch();

	const handleClose = React.useCallback(() => {
		setIsOpen(false);
		setSelectedItem(null);
		setIsOpenAutoReport && setIsOpenAutoReport(false);
	}, [setIsOpenAutoReport]);

	React.useEffect(() => {
		if (isOpenAutoReport) {
			setIsOpen(true);
		}
	}, [isOpenAutoReport]);

	const getAllReportSubscription = React.useCallback(async () => {
		setLoading(true);
		await getReportSubscription({ page }).then((res) => {
			if (res && res.length > 0) {
				setData(res);
				setLoading(false);
			} else {
				setLoading(false);
				setData([]);
			}
		});
	}, [page]);

	React.useEffect(() => {
		if (isOpen) {
			getAllReportSubscription();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleEdit = React.useCallback((row) => {
		setSelectedItem(row);
	}, []);

	const handleSave = React.useCallback(async () => {
		if (selectedItem?._id) {
			let updateData = {
				name: selectedItem?.name,
				interval: selectedItem?.interval,
			};
			await updateReportSubscription(selectedItem?._id, updateData).then((res) => {
				if (res && res.status === 200) {
					getAllReportSubscription();
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "success",
							label: `Record updated successfully`,
							id: "removeSubscription",
						},
					});
				} else {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: `Something went wrong, Please try again later`,
							id: "removeSubscriptionErr",
						},
					});
				}
			});
			setSelectedItem(null);
		}
	}, [dispatch, getAllReportSubscription, selectedItem]);

	const handleDelete = React.useCallback(
		async (id) => {
			await removeReportSubscription(id).then((res) => {
				if (res && res.status === 200) {
					getAllReportSubscription();
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "success",
							label: `Record deleted successfully`,
							id: "removeSubscription",
						},
					});
				} else {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: `Something went wrong, Please try again later`,
							id: "removeSubscriptionErr",
						},
					});
				}
			});
		},
		[dispatch, getAllReportSubscription]
	);

	if (isAutomaticReportSaved) {
		return (
			<>
				{selectedSortCut?.isAutoReport && (
					<Box>
						<Tooltip title={"Automatic Reports"} arrow>
						<IconButton
							onClick={() => setIsOpen(true)}
							size="small"
							disableFocusRipple={false}
							disableRipple={false}
							sx={{
								width: 30,
								height: 30,
								ml: 1,
								borderRadius: "4px",
								'&:hover': {
									backgroundColor: '#4879f5',
									color: "white",
								},
							}}
						>
							<AutoModeIcon fontSize="small" />
						</IconButton>
						</Tooltip>
					</Box>
				)}
				{isOpen && (
					<Dialog fullWidth={true} maxWidth={"md"} open={isOpen} onClose={handleClose}>
						<DialogTitle>Automatically sent report list</DialogTitle>
						<DialogContent>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 550 }} aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell>Report name</TableCell>
											<TableCell align="center">Report Type</TableCell>
											<TableCell align="center">Card Title</TableCell>
											<TableCell align="center">Report register date</TableCell>
											<TableCell align="center">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{loading && (
											<TableRow key={"loading"}>
												<TableCell colSpan={5}>
													<Box display={"flex"} justifyContent={"center"} sx={{ mt: 1, height: "50px" }}>
														<CircularProgress />
													</Box>
												</TableCell>
											</TableRow>
										)}
										{!loading &&
											data.length > 0 &&
											data.map((row) => {
												const ID = row?._id;
												const isEdit = selectedItem?._id === ID;
												return (
													<TableRow
														key={row.name}
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															{isEdit ? (
																<TextField
																	size="small"
																	value={selectedItem?.name}
																	onChange={(e) =>
																		setSelectedItem((prevState) => ({
																			...prevState,
																			name: e.target.value,
																		}))
																	}
																/>
															) : (
																row.name
															)}
														</TableCell>
														{isEdit ? (
															<TableCell>
																<FormControl fullWidth>
																	<Select
																		labelId="interval-simple-select-label"
																		id="interval-simple-select"
																		size="small"
																		value={selectedItem?.interval}
																		onChange={(e) =>
																			setSelectedItem((prevState) => ({
																				...prevState,
																				interval: e.target.value,
																			}))
																		}
																	>
																		<MenuItem value={AUTOMATICALLY_REPORT_TYPE.DAILY}>
																			{_.capitalize(AUTOMATICALLY_REPORT_TYPE.DAILY)}
																		</MenuItem>
																		<MenuItem value={AUTOMATICALLY_REPORT_TYPE.MONTHLY}>
																			{_.capitalize(AUTOMATICALLY_REPORT_TYPE.MONTHLY)}
																		</MenuItem>
																		<MenuItem value={AUTOMATICALLY_REPORT_TYPE.YEARLY}>
																			{_.capitalize(AUTOMATICALLY_REPORT_TYPE.YEARLY)}
																		</MenuItem>
																	</Select>
																</FormControl>
															</TableCell>
														) : (
															<TableCell align="center">{_.capitalize(row.interval)}</TableCell>
														)}
														<TableCell align="center">
															{row?.title} {row?.adtType != null && "(ADT)"}
														</TableCell>
														<TableCell align="center">{dateFormat(row.createdAt)}</TableCell>
														<TableCell align="center">
															<Stack spacing={2} direction={"row"} justifyContent={"center"}>
																{isEdit ? (
																	<>
																		<Tooltip title="Update">
																			<SaveAsIcon sx={{ cursor: "pointer" }} color="primary" onClick={handleSave} />
																		</Tooltip>
																		<Tooltip title="Cancel">
																			<CloseIcon
																				sx={{ cursor: "pointer" }}
																				color="primary"
																				onClick={() => setSelectedItem(null)}
																			/>
																		</Tooltip>
																	</>
																) : (
																	<>
																		<Tooltip title="Edit">
																			<EditNoteIcon
																				onClick={() => handleEdit(row)}
																				sx={{ cursor: "pointer" }}
																				color="primary"
																			/>
																		</Tooltip>
																		<Tooltip title="Delete">
																			<DeleteOutlineOutlinedIcon
																				onClick={() => handleDelete(row?._id)}
																				sx={{ cursor: "pointer" }}
																				color="error"
																			/>
																		</Tooltip>
																	</>
																)}
															</Stack>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
									{data.length === 0 && !loading && <NoRecordFound data={data} />}
								</Table>
							</TableContainer>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Close</Button>
						</DialogActions>
					</Dialog>
				)}
			</>
		);
	}

	return null;
}
