import axios from "../axios";
import { useEffect, useMemo } from "react";
import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import Login from "../login/Login";
import Home from "../components/home/Home";
import { LOGIN, SET_ACCOUNTS, SET_FACILITIES } from "../store/types";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../components/reset-password/ResetPassword";
import SettingsPage from "../components/settings/Settings";
import Logs from "../components/logs/Logs";
import AccountFacilities from "../pages/settings/Facilities";
import DataInput from "../components/new-data-input/DataInput";
import Dashboard from "../components/dashboard/Dashboard";
import HospitalPage from "../pages/Hospital";
import AccountsPage from "../pages/settings/AccountsPage";
import RolePage from "../pages/Roles";
import {
    setFacilityAccess,
    setRoleName,
} from "../store/reducers/permission.slice";
import _ from "lodash";
import AdmissionPage from "../pages/Admission";
import CommunityTransferPage from "../pages/CommunityTransfer";
import DeceasedPage from "../pages/Deceased";
import OverallPage from "../pages/Overall";
// import QuickGlacePage from "../pages/QuickGlace";
import AccountUserPage from "../pages/settings/UsersPage";
import { AUTH_ROLES } from "../types/auth.type";
import ManageProfilePage from "../pages/settings/ManageProfilePage";
import ForgotPassword from "../login/forgot-password/ForgotPassword";
import { setFacilityLoaded, setSelectedAccount } from "../store/reducers/common.slice";
import useSetParamToURL from "../components/hooks/useSetParamToURL";
import VersionPage from "../version/version";


const AppRouter = (props) => {
    const { auth, account, facility, permission } = useSelector(
        ({ auth, account, facility, permission, }) => ({
            auth,
            account,
            facility,
            permission
        })
    );
    const { selectedAccount } = useSelector((state) => state.common);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { jwt } = props;
    const { setParamToURL, setPathWithParams } = useSetParamToURL();
    const [searchParams] = useSearchParams();

    const accountId = useMemo(() => {
        const selectedAccountId = selectedAccount?._id || searchParams.get("accountId");
        localStorage.setItem("accountId", selectedAccountId);
        return selectedAccountId;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, searchParams.get("accountId")]);

    const getUser = async () => {
        let re = await axios.get("/api/user").catch((e) => e);
        if (re?.response?.status === 403 || re?.response?.status === 500) {
            localStorage.removeItem("x-auth");
            return navigate("/login");
        }
        const { user = null } = re.data;
        if (user) {
            await dispatch({ type: LOGIN, payload: user });
            await dispatch(setRoleName(user?.role?.slug));
        } else {
            return navigate("/login");
        }
    };

    const getAccount = async () => {
        let re = await axios.get("/api/account").catch((e) => e);
        if (re?.response?.status === 403) {
            localStorage.removeItem("x-auth");
            return navigate("/login");
        }

        await dispatch({ type: SET_ACCOUNTS, payload: re.data });

        if (!accountId) {
            setParamToURL([{ key: "accountId", value: re.data[0]._id }]);
            localStorage.setItem("accountId", re.data[0]._id);
            dispatch(setSelectedAccount(re.data[0]));
        }
    };

    const getFacilities = async () => {
        let storedAccountId = accountId;
        if (!storedAccountId) {
            storedAccountId = account[0]._id;
        }

        let re2 = await axios
            .get(`/api/facility/list/${storedAccountId}`)
            .catch((e) => e);
        if (re2?.response?.status === 403) {
            localStorage.removeItem("x-auth");
            return navigate("/login");
        }
        if (re2.data && re2.data.length > 0) {
            await dispatch(setFacilityLoaded(false));
        } else {
            await dispatch(setFacilityLoaded(true));
        }
        await dispatch({ type: SET_FACILITIES, payload: re2.data });
        let storedFacilityId = localStorage.getItem("facilityId");
        if (re2.data.length === 0) return;
        if (!storedFacilityId) {
            storedFacilityId = re2.data[0]._id;
            localStorage.setItem("facilityId", storedFacilityId);
        }

        if (permission && permission.role === AUTH_ROLES.USER) {
            let facilityIdUpdated = storedFacilityId;
            if (re2.data.length > 0 && _.find(re2.data, { _id: facilityIdUpdated }) === undefined) {
                facilityIdUpdated = re2.data[0]?._id;
            }
            const selectedFacilityItem = _.find(re2.data, { _id: facilityIdUpdated });
            if (selectedFacilityItem) {
                await dispatch(
                    setFacilityAccess({
                        read: selectedFacilityItem.read,
                        write: selectedFacilityItem.write,
                    })
                );

                if (selectedFacilityItem.read && !selectedFacilityItem.write) {
                    return setPathWithParams("/dashboard/hospital", [{ key: "accountId", value: storedAccountId }, { key: "facilityId", value: selectedFacilityItem._id }]);
                }
                if (!selectedFacilityItem.read && selectedFacilityItem.write) {
                    return setPathWithParams("/", [{ key: "accountId", value: storedAccountId }, { key: "facilityId", value: selectedFacilityItem._id }]);
                }
            }
        } else {
            return setPathWithParams("/dashboard/hospital", [{ key: "accountId", value: storedAccountId }, { key: "facilityId", value: storedFacilityId }]);
        }
    };

    useEffect(() => {
        if (!account || !Array.isArray(account) || !account?.length) return;
        getFacilities();
        // eslint-disable-next-line
    }, [account]);

    useEffect(() => {
        if (!(account === "refreshing" || facility === "refreshing")) return;

        if (account === "refreshing") {
            getAccount();
        }
        if (account === "refreshing" || facility === "refreshing") {
            getFacilities();
        }
        // eslint-disable-next-line
    }, [account, facility]);

    useEffect(() => {
        if (!jwt) return;
        getUser();
        getAccount();
        // eslint-disable-next-line
    }, []);

    useMemo(() => {
        if (selectedAccount && selectedAccount?.dashboardAccess && selectedAccount?.dashboardAccess.length === 1 && (
            location.pathname.includes("admissions") ||
            location.pathname.includes("community-transfer") ||
            location.pathname.includes("deceased") ||
            location.pathname.includes("overall")
        )) {
            setPathWithParams("/");
        }
    }, [selectedAccount, location.pathname, setPathWithParams]);

    return (
        <>
            <Routes>
                {(location.pathname !== "/login" || location.pathname !== "/new-password") && !jwt ? (
                    <>
                        <Route path="/*" element={<Navigate to="/login" />} />
                    </>
                ) : undefined}

                {auth?.requirePasswordReset ? (
                    <Route
                        path="/*"
                        element={<Navigate to="/reset-password" />}
                    />
                ) : undefined}
                <Route path="/login" replace element={<Login jwt={jwt} />}></Route>
                <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                <Route path="/version" element={<VersionPage />}></Route>
                <Route path="/new-password/:token?" element={<ResetPassword />}></Route>
                {jwt ? (
                    <Route path="/reset-password" element={<ResetPassword />}></Route>
                ) : undefined}

                {jwt && !auth?.requirePasswordReset ? (
                    <>
                        {account !== null && facility !== null ? (
                            <Route path="/" replace element={<Home />}>
                                <Route path="/" element={<DataInput />}></Route>

                                <Route path="/dashboard" element={<Dashboard />}>
                                    <Route path="hospital" replace element={<HospitalPage />} />
                                    <Route path="admissions" element={<AdmissionPage />} />
                                    <Route
                                        path="community-transfer"
                                        element={<CommunityTransferPage />}
                                    />
                                    <Route path="deceased" element={<DeceasedPage />} />
                                    <Route path="overall" element={<OverallPage />} />
                                    {/* <Route path="quick-glace" element={<QuickGlacePage />} /> */}
                                </Route>

                                <Route path="logs" element={<Logs />}></Route>
                                <Route path="settings" element={<SettingsPage />}>
                                    <Route path="profile" element={<ManageProfilePage />} />
                                    <Route path="users" element={<AccountUserPage />} />
                                    <Route path="accounts" element={<AccountsPage />} />
                                    <Route path="roles" element={<RolePage />} />
                                    <Route path="facilities" element={<AccountFacilities />} />
                                </Route>
                            </Route>
                        ) : undefined}
                    </>
                ) : undefined}
            </Routes>
        </>
    );
};

export default AppRouter;
