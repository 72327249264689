import axios from "../axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppContext from "../context/AppContext";
import { LOGIN, SET_ACCOUNTS, ADD_NOTIFICATION } from "../store/types";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "./Login.module.scss";
import { setRoleName } from "../store/reducers/permission.slice";
import { LoadingButton } from "@mui/lab";
import { Container, Stack } from "@mui/system";
import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TempLogo from "../assets/svgs/temp-logo.svg";
import { Form, FormikProvider, useFormik } from "formik";
import { LoginSchema } from "../validations/LoginSchema";
import { authLogin } from "../services/user.service";
import { setSelectedAccount } from "../store/reducers/common.slice";
import useSetParamToURL from "../components/hooks/useSetParamToURL";
import _ from "lodash";
import authConfig from "../auth_config";
import { getUser } from "../services/api/user.api";

const Login = (props) => {
  const { jwt } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { setJwt } = useContext(AppContext);
  const dispatch = useDispatch();
  const { setPathWithParams } = useSetParamToURL();
  const [accountIdLocal, setAccountIdLocal] = useState(null);
  const [tokenProcessed, setTokenProcessed] = useState(false);
  const [autoLoginRedirectProcessed, setAutoLoginRedirectProcessed] = useState(false);
  const [loginRedirectProcessed, setLoginRedirectProcessed] = useState(false);
  const handleShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);
  const [buttonAuth0Text, setButtonAuth0Text] = useState("Log in to SNF Data");

  const isAuth0Enabled = process.env.REACT_APP_AUTH0_DOMAIN && process.env.REACT_APP_AUTH0_CLIENT_ID;

  const getAccount = useCallback(async () => {
    let re = await axios.get("/api/account").catch((e) => e);
    if(!re.data && re.data.length === 0) return;
    const accountsData = _.orderBy(re.data, ["name"], ["asc"]);    
    await dispatch({ type: SET_ACCOUNTS, payload: accountsData });
    if(accountsData.length === 0) return;
    // let storedAccountId = re.data[0]._id // localStorage.getItem("accountId");

    dispatch(setSelectedAccount(accountsData[0]));
    setAccountIdLocal(accountsData[0]._id);

    // if (!storedAccountId || !_.find(re.data, { _id: storedAccountId })) {
    //   localStorage.setItem("accountId", re.data[0]._id);
    //   dispatch(setSelectedAccount(re.data[0]));
    //   setAccountIdLocal(re.data[0]._id);
    // } else {
    //   dispatch(setSelectedAccount(_.find(re.data, { _id: storedAccountId })));
    //   setAccountIdLocal(storedAccountId);
    // }
  }, [dispatch, setAccountIdLocal]);

  const loginWithRedirect = useCallback( () => {
    if(!loginRedirectProcessed){
      setLoginRedirectProcessed(true);
    }
    else {
      return;
    }

    setButtonAuth0Text("Logging you in ...");
    const clientId = authConfig.clientId;
    const domain = authConfig.domain;
    const redirectUri = authConfig.redirectUri;

    const scope = 'openid profile email';

    const authUrl = `https://${domain}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&prompt=login`;

    setTimeout(() => {
      window.location.href = authUrl;
    }, 500);
  }, [loginRedirectProcessed, setLoginRedirectProcessed, setButtonAuth0Text]);

  useEffect(() => {
    const handleAuthToken = async () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.split('?')[1]);
      const token = params.get('token');
      const error = params.get('error');

      if (jwt && accountIdLocal) {
        setPathWithParams("/", [{ key: "accountId", value: accountIdLocal }])
      }
      else if(!tokenProcessed && (token || error)) {
        const errorMessage = params.get('message');

        if (error) {
          const decodedErrorMessage = decodeURIComponent(errorMessage);
    
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              type: "error",
              label: decodedErrorMessage || "Authentication failed, please try again.",
              id: "updateUserError",
            },
          });

          return;
        }

        if (token) {
          try {
            axios.defaults.headers.common['x-auth'] = token;
            sessionStorage.setItem('x-auth', token);
            const res = await getUser();
            setTokenProcessed(true);
            if (res && res.user) {
              dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                  type: "success",
                  label: "User logged successfully.",
                  id: "updateUserError",
                },
              });

              const user = res.user;
              await dispatch({ type: LOGIN, payload: user });
              await dispatch(setRoleName(user?.role?.slug));
              await getAccount();
              await setJwt(token);  
            }
          } catch (error) {
            console.error('Error handling token or fetching user:', error);
          }
        }
      }
      else if (!autoLoginRedirectProcessed && isAuth0Enabled && !token && !error && !jwt && !accountIdLocal) {
        setAutoLoginRedirectProcessed(true);
      }
    }

    handleAuthToken();
  }, [
    accountIdLocal,
    jwt,
    setPathWithParams,
    dispatch,
    getAccount,
    setJwt,
    isAuth0Enabled,
    setTokenProcessed,
    tokenProcessed,
    setAutoLoginRedirectProcessed,
    autoLoginRedirectProcessed,
  ]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      await authLogin(values)
        .then(async (res) => {
          setSubmitting(false);
          if (res) {
            const { token, user = null } = res;
            axios.defaults.headers.common["x-auth"] = token;
            sessionStorage.setItem("x-auth", token);
            await dispatch({ type: LOGIN, payload: user });
            await dispatch(setRoleName(user?.role?.slug));
            await getAccount();
            await setJwt(token);
            resetForm();
          }
        })
        .catch((err) => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "80vh" }}
        >
          <Grid sx={{ padding: 5 }}>
            <TempLogo />
          </Grid>
          <Grid>
            <Typography variant="h5">Welcome to SNF Data</Typography>
          </Grid>
          <Grid sx={{ mt: 4 }} width="80%">
            {isAuth0Enabled ? (
              <Stack spacing={2} maxWidth="sm">
                <LoadingButton
                  variant="contained"
                  size="large"
                  onClick={() => loginWithRedirect()}
                >
                  {buttonAuth0Text}
                </LoadingButton>
              </Stack>
            ) : (
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={2} maxWidth="sm">
                    <Stack spacing={3}>
                      <FormControl>
                        <FormLabel>Username</FormLabel>
                        <TextField
                          {...getFieldProps("username")}
                          fullWidth
                          autoComplete="off"
                          type={"text"}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Password</FormLabel>
                        <TextField
                          {...getFieldProps("password")}
                          fullWidth
                          autoComplete="off"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleShowPassword}
                                  edge="end"
                                  size="small"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </FormControl>
                    </Stack>
                    <Stack>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        loadingIndicator="Loading..."
                        size="large"
                      >
                        Login
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            )}
          </Grid>
          {!isAuth0Enabled && (
            <Grid sx={{ mt: 2 }}>
              <Link
                to="/forgot-password"
                className={`df aic ffmar fs15 ${styles.selectionOption} ${styles.forUser}`}
              >
                Forgot Password ?
              </Link>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Login;
