import api from "./api";
import { filterBodyDate, toSaveDate, toSaveEndDate } from "../../utilis/date-formats";
const API_PREFIX = "api/hospital";

const getDateWiseData = async (params) => {
	const apiParams = { ...params };
	apiParams.startDate = toSaveDate(params.startDate);
	apiParams.endDate = toSaveEndDate(params.endDate);
	const response = await api.get(`${API_PREFIX}/dashboard-data`, { params: apiParams });
	return response;
};

const getValidations = async () => {
	const response = await api.get(`api/validation/all`);
	return response;
};

const getCardPatientChartData = async (body) => {
	const response = await api.post(`${API_PREFIX}/card-patient-data`, body);
	return response;
};

const getCardPatientAndChartData = async (body = null, { signal } = {}) => {
	body = filterBodyDate(body);
	if (body.transferType === "all") {
		delete body["transferType"];
	}
	const config = {
		headers: { 'Content-Type': 'application/json' }, // Optional, specify headers if needed
		signal, // Pass the AbortController's signal here
	};

	const response = await api.post(`${API_PREFIX}/card-patient-chart-data`, body, config);
	return response;
};

const getAllCount = async (params,{ signal } = {}) => {
	const apiParams = { ...params };
	apiParams.startDate = toSaveDate(params.startDate);
	apiParams.endDate = toSaveEndDate(params.endDate);

	const config = {
        params: apiParams,
        signal, // Pass the AbortController's signal here
    };

	const response = await api.get(`${API_PREFIX}/get-all-count`, config);
	return response;
};

export { getDateWiseData, getValidations, getCardPatientAndChartData, getCardPatientChartData, getAllCount };
